<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.postofficeid" :items="postoffices" label="组开局"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.postroutelevel" :items="['一级干线', '二级干线']" label="邮路级别"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.province" :items="provinces" label="省份"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.destprovince" :items="destprovinces" label="寄达省"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="6">
        <a-space>
          <v-text-field v-model="queryForm.departure" label="始发" dense outlined clearable hide-details /> →
          <v-text-field v-model="queryForm.destination" label="到达" dense outlined clearable hide-details />
        </a-space>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-text-field v-model="queryForm.name" label="线路名称" dense outlined clearable hide-details />
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-text-field v-model="queryForm.transport_name" label="去程/返程" dense outlined clearable hide-details />
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-autocomplete v-model="queryForm.transport_kind" :items="transport_kinds" label="单程/往返"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-autocomplete v-model="queryForm.corp" :items="corps" label="所属公司"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="4">
        <v-btn @click="getdata" color="primary" tile style="width:80%;">查询</v-btn>
      </v-col>
    </v-row>
    <v-card tile>
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex justify-space-between align-center pl-2">
            <div>
              <span>共 {{ length }} 页 / {{ count }} 条数据</span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                    {{ limit }}/页
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-btn v-if="username === 'root'" tile text color="success" @click="copyall">
              复制全部
            </v-btn>
          </div>
        </template>
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
          <v-btn v-if="username === 'root'" tile text color="success" @click="copyid(record)">
            复制
          </v-btn>
        </template>
        <template slot="customA">
          <p>省份</p>
          <p>线路名称</p>
          <p>寄达省</p>
        </template>
        <template slot="customB">
          <p>所属公司</p>
          <p>邮路级别</p>
          <p>单程/往返</p>
        </template>
        <template slot="customD">
          <p>邮局合同</p>
          <p>组开局</p>
        </template>
        <template slot="customG">
          <p>承运商合同</p>
        </template>
        <template slot="A" slot-scope="text, record">
          <p>{{ record.province }}</p>
          <p>{{ record.name }}</p>
          <p>{{ record.destprovince }}</p>
        </template>
        <template slot="B" slot-scope="text, record">
          <p>{{ record.corp }}</p>
          <p>{{ record.postroutelevel }}</p>
          <p>{{ record.transport_kind }}</p>
        </template>
        <template slot="transport_out" slot-scope="text, record">
          <div class="d-flex flex-column align-center">
            <a-tooltip v-for="(item, index) in record.transport_list" :key="index"
              :overlayStyle="{ maxWidth: '400px' }">
              <template slot="title">
                {{ item.transport_out }}
              </template>
              {{ item.transport_out }}
            </a-tooltip>
          </div>
        </template>
        <template slot="transport_in" slot-scope="text, record">
          <div class="d-flex flex-column align-center">
            <a-tooltip v-for="(item, index) in record.transport_list" :key="index"
              :overlayStyle="{ maxWidth: '400px' }">
              <template slot="title">
                {{ item.transport_in }}
              </template>
              {{ item.transport_in }}
            </a-tooltip>
          </div>
        </template>
        <template slot="D" slot-scope="text, record">
          <v-btn tile text color="success" @click="showupcontracts(record)">
            邮局合同({{ record.upcontract_count }})
          </v-btn>
          <p class="success--text">{{ getpostofficename(record) }}</p>
        </template>
        <template slot="G" slot-scope="text, record">
          <v-btn tile text color="error" @click="showdowncontracts(record)">
            承运商合同({{ record.downcontract_count }})
          </v-btn>
        </template>
        <template slot="create_time" slot-scope="text, record">
          <p>{{ record.create_time }}</p>
          <p>{{ record.create_by }}</p>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
            <a-popconfirm title="删除线路" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
              <v-btn text tile color="error"> 删除 </v-btn>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="contractsModel" fullscreen persistent max-width="1000px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon tile @click="contractsModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>合同管理</v-toolbar-title>
          <v-toolbar-title class="ml-2">{{ contractsTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn tile text @click="contractsModel = false"> 关闭 </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <div class="d-flex justify-space-between">
              <a-tabs type="card" v-model="contractType" @change="showcontracts" size="large">
                <a-tab-pane key="up" tab="邮局合同">
                </a-tab-pane>
                <a-tab-pane key="down" tab="承运商合同">
                </a-tab-pane>
              </a-tabs>
              <a-space>
                <v-btn color="cyan" dark tile @click="bulkcopycontract">批量复制</v-btn>
                <v-btn v-if="contractType === 'up'" color="success" tile @click="addcontract">新增邮局合同</v-btn>
                <v-btn v-if="contractType === 'down'" color="error" tile @click="addcontract">新增(承运商)合同</v-btn>
              </a-space>
            </div>
            <a-table size="small" :columns="contractType === 'up' ? cheaders1 : cheaders2" :data-source="contracts"
              :pagination="false" :locale="{ emptyText: '暂无数据' }" :scroll="{ y: height }" row-key="_id"
              :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
              <template slot="price" slot-scope="text, record">
                <div>
                  {{ record.price }}<span v-if="record.price_fixed" class="error--text"> (固定)</span>
                </div>
              </template>
              <template slot="contract_duration" slot-scope="text, record">
                <p :class="{ 'success--text': record.running }">{{ record.start_day }}~{{ record.end_day }}</p>
              </template>
              <template slot="create_time" slot-scope="text, record">
                <p>{{ record.create_time }}</p>
                <p>{{ record.create_by }}</p>
              </template>
              <template slot="action" slot-scope="text, record">
                <a-space>
                  <v-btn text tile color="success" @click="editcontract(record)"> 编辑 </v-btn>
                  <v-btn text tile color="purple" @click="copycontract(record)"> 复制 </v-btn>
                  <a-popconfirm title="删除合同" ok-text="确定" cancel-text="取消" @confirm="removecontract(record._id)">
                    <v-btn text tile color="error"> 删除 </v-btn>
                  </a-popconfirm>
                </a-space>
              </template>
            </a-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="contractModel" persistent max-width="600px">
      <v-form ref="contractForm" v-model="contractValid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ contractTitle }}
            <v-icon @click="cancelsavecontract">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="24">
                  <v-autocomplete v-model="contractForm.transport" :items="transport_list" :rules="[vrules.required]"
                    :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined
                    @change="(e) => changeTransport(e)">
                    <template v-slot:label>
                      去程/返程 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <template v-if="contractType === 'up'">
                  <v-col cols="12" md="6">
                    <v-text-field v-model="contractForm.km" :rules="[vrules.required]" type="number" dense outlined>
                      <template v-slot:label>
                        里程 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="contractForm.price" :rules="[vrules.required]" type="number" dense outlined>
                      <template v-slot:label>
                        收入 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </template>
                <template v-if="contractType === 'down'">
                  <v-col cols="12" md="6">
                    <v-text-field v-model="contractForm.price" :rules="[vrules.required]" type="number" dense outlined>
                      <template v-slot:label>
                        成本 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                  </v-col>
                </template>
                <v-col cols="12" md="6">
                  <v-menu ref="contract_start_menu" v-model="contract_start_menu" :close-on-content-click="false"
                    :return-value.sync="contractForm.start_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="contractForm.start_day" :rules="[vrules.required]"
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          合同期限开始 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contractForm.start_day" no-title scrollable locale="zh-cn"
                      @input="() => { $refs.contract_start_menu.save(contractForm.start_day); contract_start_menu = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu ref="contract_end_menu" v-model="contract_end_menu" :close-on-content-click="false"
                    :return-value.sync="contractForm.end_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="contractForm.end_day" :rules="[vrules.required]" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          合同期限结束 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contractForm.end_day" no-title scrollable locale="zh-cn"
                      @input="() => { $refs.contract_end_menu.save(contractForm.end_day); contract_end_menu = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="contractForm.remark" outlined rows="2" label="备注"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!contractValid" @click="savecontract">保存</v-btn>
            <v-btn tile @click="cancelsavecontract">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "PostLineList",
  data() {
    return {
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        { text: '200/页', value: 200 },
      ],
      queryForm: {
        name: "",
        postofficeid: undefined,
        postroutelevel: undefined,
        province: undefined,
        destprovince: undefined,
        departure: "",
        destination: "",
        carrierid: undefined,
        transport_kind: undefined,
        transport_name: "",
        corp: undefined,
      },
      postoffices: [],
      provinces: [],
      destprovinces: [],
      transport_kinds: [],
      corps: [],
      valid: true,
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 100, align: "center" },
        { dataIndex: "A", slots: { title: "customA" }, scopedSlots: { customRender: "A" }, width: 150, align: "center", },
        { dataIndex: "B", slots: { title: "customB" }, scopedSlots: { customRender: "B" }, width: 120, align: "center", },
        { title: "去程", dataIndex: "transport_out", scopedSlots: { customRender: "transport_out" }, width: 200, align: "center", },
        { title: "返程", dataIndex: "transport_in", scopedSlots: { customRender: "transport_in" }, width: 200, align: "center", },
        { dataIndex: "D", slots: { title: "customD" }, scopedSlots: { customRender: "D" }, width: 120, align: "center", },
        { dataIndex: "G", slots: { title: "customG" }, scopedSlots: { customRender: "G" }, width: 120, align: "center", },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 200, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 180, align: "center", fixed: 'right' },
      ],
      cheaders1: [
        { title: "去程", dataIndex: "transport_out", scopedSlots: { customRender: "transport_out" }, width: 180, align: "center" },
        { title: "返程", dataIndex: "transport_in", scopedSlots: { customRender: "transport_in" }, width: 180, align: "center" },
        { title: "里程", dataIndex: "km", scopedSlots: { customRender: "km" }, width: 120, align: "center" },
        { title: "收入", dataIndex: "price", scopedSlots: { customRender: "price" }, width: 100, align: "center" },
        { title: "合同期限", dataIndex: "contract_duration", scopedSlots: { customRender: "contract_duration" }, width: 200, align: "center" },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 200, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 200, align: "center" },
      ],
      cheaders2: [
        { title: "去程", dataIndex: "transport_out", scopedSlots: { customRender: "transport_out" }, width: 180, align: "center" },
        { title: "返程", dataIndex: "transport_in", scopedSlots: { customRender: "transport_in" }, width: 180, align: "center" },
        { title: "成本", dataIndex: "price", scopedSlots: { customRender: "price" }, width: 120, align: "center" },
        { title: "合同期限", dataIndex: "contract_duration", scopedSlots: { customRender: "contract_duration" }, width: 200, align: "center" },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 200, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 200, align: "center" },
      ],
      selectedRowKeys: [],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      contractsModel: false,
      contractsTitle: '',
      contracts: [],
      postlineid: '',
      contractType: '',
      contractModel: false,
      contractTitle: '',
      contractValid: true,
      contractForm: {},
      contract_start_menu: false,
      contract_end_menu: false,
      transport_list: [],
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
    cwidth1() {
      return _.reduce(this.cheaders1, (a, b) => a + (b.width || 300), 0);
    },
    cwidth2() {
      return _.reduce(this.cheaders2, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.getdata();
  },
  mounted() {
    this.height = window.innerHeight - 300;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 300;
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.postoffices = res.data.postoffices;
          this.provinces = res.data.provinces;
          this.destprovinces = res.data.destprovinces;
          this.transport_kinds = res.data.transport_kinds;
          this.corps = res.data.corps;
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showupcontracts(item) {
      this.postlineid = item._id;
      this.contractType = 'up';
      this.contractsTitle = `${item.name} ${item.transport_kind} ${item.postroutelevel}`
      this.transport_list = _.map(item.transport_list, n => (
        {
          transport_out: n.transport_out, transport_in: n.transport_in,
          text: `${n.transport_out}/${n.transport_in}`,
          label: `${n.transport_out}/${n.transport_in}`,
          value: `${n.transport_out}/${n.transport_in}`,
        }))
      this.showcontracts();
    },
    showdowncontracts(item) {
      this.postlineid = item._id;
      this.contractType = 'down';
      this.contractsTitle = `${item.name} ${item.transport_kind} ${item.postroutelevel}`
      this.transport_list = _.map(item.transport_list, n => (
        {
          transport_out: n.transport_out, transport_in: n.transport_in,
          text: `${n.transport_out}/${n.transport_in}`,
          label: `${n.transport_out}/${n.transport_in}`,
          value: `${n.transport_out}/${n.transport_in}`,
        }))
      this.showcontracts();
    },
    showcontracts() {
      this.selectedRowKeys = [];
      this.contracts = [];
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/contracts", { postlineid: this.postlineid, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contracts = _.map(res.data.list, n => {
            return {
              ...n,
              running: n.start_t <= Date.now() && n.end_t >= Date.now(),
            }
          });
          this.contractsModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    addcontract() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/contractinfo", { contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractForm = res.data.contractForm;
          this.contractTitle = `新增${this.contractType === 'up' ? '邮局' : '承运商'}合同`;
          this.contractModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    editcontract(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/contractinfo", { _id: item._id, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractForm = res.data.contractForm;
          this.contractTitle = `编辑${this.contractType === 'up' ? '邮局' : '承运商'}合同`;
          this.contractModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    bulkcopycontract() {
      if (this.selectedRowKeys.length === 0) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/bulkcopycontract", { ids: this.selectedRowKeys, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.showcontracts();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    copycontract(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/contractinfo", { _id: item._id, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractForm = { ...res.data.contractForm, _id: "" };
          this.contractTitle = `新增${this.contractType === 'up' ? '邮局' : '承运商'}合同`;
          this.contractModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    removecontract(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/removecontract", { _id, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.showcontracts();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    savecontract() {
      if (!this.$refs.contractForm.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/savecontract", { postlineid: this.postlineid, contractType: this.contractType, contractForm: this.contractForm }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractModel = false;
          this.showcontracts();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsavecontract() {
      this.$refs.contractForm.resetValidation();
      this.contractModel = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    changeTransport(e) {
      console.log('changeTransport', e);
      let transport = _.findWhere(this.transport_list, { value: e });
      this.contractForm.transport_out = transport?.transport_out;
      this.contractForm.transport_in = transport?.transport_in;
    },
    copyid(item) {
      let that = this;
      this.$copyText(item._id).then(
        function (e) {
          that.$toast.success("复制成功");
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    copyall() {
      let that = this;
      let list = _.pluck(this.list, "_id");
      this.$copyText(JSON.stringify(list)).then(
        function (e) {
          that.$toast.success("复制成功");
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    getpostofficename(item) {
      let postoffice = _.findWhere(this.postoffices, { value: item.postofficeid });
      return postoffice?.text;
    },
    showedit(item) {
      // TODO
      this.$router.replace({ path: "/postline/nanning/create", query: { id: item._id } });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

p {
  margin: 0;
}
</style>
