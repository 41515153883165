import Vue from "vue"
import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"
import _ from "underscore"
import utils from "../utils";

Vue.use(Vuex)

const state = {
  initmenus: [
    {
      key: utils.uuid(),
      icon: "mdi-view-dashboard",
      name: "每日数据统计",
      active: false,
      roles: [],
      path: "/daily",
    },
    {
      key: utils.uuid(),
      icon: "mdi-post-outline",
      name: "邮局管理",
      active: false,
      roles: ["root", "admin", "pm", "caiwu", "kuaiji", "chuna", "jiesuan"],
      children: [
        {
          key: utils.uuid(),
          name: "邮局列表",
          active: false,
          path: "/postoffice/list",
        },
        {
          key: utils.uuid(),
          name: "油价联动",
          active: false,
          // username: "root",
          path: "/postoffice/oilprice",
        },
        {
          key: utils.uuid(),
          name: "车辆比例系数",
          active: false,
          path: "/postoffice/carratio",
        }
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-map-marker-path",
      name: "线路管理",
      active: false,
      roles: ["root", "admin", "pm", "caiwu", "kuaiji", "chuna", "jiesuan"],
      children: [
        {
          key: utils.uuid(),
          name: "线路列表",
          active: false,
          path: "/postline/list",
        },
        {
          key: utils.uuid(),
          name: "添加线路",
          active: false,
          path: "/postline/create",
        }
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-map-marker-path",
      name: "线路管理(南宁)",
      active: false,
      roles: ["root", "admin", "pm", "caiwu", "kuaiji", "chuna", "jiesuan"],
      children: [
        {
          key: utils.uuid(),
          name: "线路列表",
          active: false,
          path: "/postline/nanning/list",
        },
        {
          key: utils.uuid(),
          name: "添加线路",
          active: false,
          path: "/postline/nanning/create",
        }
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-account-supervisor-outline",
      name: "承运商管理",
      active: false,
      roles: ["root", "admin", "pm", "caiwu", "kuaiji", "chuna", "jiesuan"],
      children: [
        {
          key: utils.uuid(),
          name: "承运商列表",
          active: false,
          path: "/carrier/list",
        },
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-clipboard-text-outline",
      name: "路单管理",
      active: false,
      children: [
        {
          key: utils.uuid(),
          name: "路单列表",
          active: false,
          path: "/orders/list",
        },
        {
          key: utils.uuid(),
          name: "路单汇总",
          active: false,
          path: "/orders/grabdata",
          roles: ["root", "admin", "pm"],
        },
        {
          key: utils.uuid(),
          name: "路单抓取",
          active: false,
          path: "/orders/roadbill",
          username: "root",
        },
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-chart-line",
      name: "车管平台数据统计",
      active: false,
      children: [
        {
          key: utils.uuid(),
          name: "运输发班统计",
          active: false,
          path: "/statistics/transport",
          roles: ["root", "admin", "pm"],
        }
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-truck-outline",
      name: "车辆管理",
      active: false,
      roles: ["root", "admin", "pm", "pm2", "jiesuan", "henan", "guangdong", "jiangxi", "zhejiang", "guangxi"],
      children: [
        {
          key: utils.uuid(),
          name: "车辆列表",
          active: false,
          path: "/vehicle/list",
        },
        {
          key: utils.uuid(),
          name: "车辆黑名单",
          active: false,
          path: "/vehicle/block",
        },
        {
          key: utils.uuid(),
          name: "车辆监控",
          active: false,
          path: "/vehicle/monit",
          username: "root",
        },
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-home-city-outline",
      name: "电子合同管理",
      active: false,
      roles: ["root", "admin", "pm", "pm2", "henan", "guangdong", "jiangxi", "zhejiang", "guangxi"],
      children: [
        {
          key: utils.uuid(),
          name: "合同列表",
          active: false,
          path: '/contract/list'
        },
        {
          key: utils.uuid(),
          name: "添加合同",
          active: false,
          path: "/contract/add",
        }
      ],
    },
    // {
    //   key: utils.uuid(),
    //   icon: "mdi-dump-truck",
    //   name: "货源发布管理",
    //   active: false,
    //   roles: ["root", "admin", "pm", "pm2", "henan", "guangdong", "jiangxi", "zhejiang", "guangxi"],
    //   children: [
    //     {
    //       key: utils.uuid(),
    //       name: "发布货源",
    //       active: false,
    //       path: '/sourcegoods/list'
    //     },
    //     {
    //       key: utils.uuid(),
    //       name: "货主管理",
    //       active: false,
    //       path: "/sourcegoods/owner",
    //     },
    //     {
    //       key: utils.uuid(),
    //       name: "订金管理",
    //       active: false,
    //       path: "/sourcegoods/subscription",
    //     }
    //   ],
    // },
    {
      key: utils.uuid(),
      icon: "mdi-finance",
      name: "财务管理",
      active: false,
      roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan", "pm", "pm2", "gm", "henan", "guangdong", "jiangxi", "zhejiang", "guangxi"],
      children: [
        {
          key: utils.uuid(),
          name: "上游账单管理",
          active: false,
          path: '/finance/upbill',
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan", "pm", "pm2", "gm"],
        },
        {
          key: utils.uuid(),
          name: "下游账单管理",
          active: false,
          path: '/finance/downbill',
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan", "pm", "pm2", "gm"],
        },
        {
          key: utils.uuid(),
          name: "自营账单管理",
          active: false,
          path: '/finance/selfbill',
          roles: [],
        },
        {
          key: utils.uuid(),
          name: "司机成本管理",
          active: false,
          path: '/finance/selfbillnanning',
          roles: [],
        },
        {
          key: utils.uuid(),
          name: "项目成本管理",
          active: false,
          path: '/finance/projectbill',
          roles: [],
        },
        {
          key: utils.uuid(),
          name: "调车价格统计",
          active: false,
          path: '/finance/selfbillchart',
          roles: [],
        },
        {
          key: utils.uuid(),
          name: "刷新应收应付",
          active: false,
          path: "/finance/refreshreceivepay",
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan", "pm", "pm2", "gm"],
          username: "root",
        },
        {
          key: utils.uuid(),
          name: "应收管理",
          active: false,
          path: "/finance/receivable",
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan", "pm", "pm2", "gm"],
        },
        {
          key: utils.uuid(),
          name: "应付管理",
          active: false,
          path: "/finance/payable",
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan", "pm", "pm2", "gm"],
        },
        {
          key: utils.uuid(),
          name: "应付(自营)管理",
          active: false,
          path: "/finance/selfpayable",
          roles: [],
        },
        {
          key: utils.uuid(),
          name: "营收日报",
          active: false,
          path: "/finance/managedaily",
          roles: ["root", "admin", "caiwu"],
        },
        {
          key: utils.uuid(),
          name: "财务报表管理",
          active: false,
          path: "/finance/report",
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan"],
        },
        {
          key: utils.uuid(),
          name: "进项票管理",
          active: false,
          path: "/finance/incomebill",
          roles: ["root", "admin", "caiwu"],
        },
        {
          key: utils.uuid(),
          name: "销项票管理",
          active: false,
          path: "/finance/outputbill",
          roles: ["root", "admin", "caiwu"],
        }
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-file-check",
      name: "结算管理",
      active: false,
      roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan"],
      children: [
        {
          key: utils.uuid(),
          name: "结算账单汇总",
          active: false,
          path: '/settle/summary',
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan"],
        },
        {
          key: utils.uuid(),
          name: "结算账单明细",
          active: false,
          path: '/settle/items',
          roles: ["root", "admin", "caiwu", "kuaiji", "chuna", "jiesuan"],
        },
      ]
    },
    {
      key: utils.uuid(),
      icon: "mdi-certificate",
      name: "证书管理",
      active: false,
      path: "/cert",
      username: "root",
      // roles: ["root", "admin"],
    },
    {
      key: utils.uuid(),
      icon: "mdi-cog",
      name: "系统管理",
      active: false,
      roles: ["root"],
      children: [
        {
          key: utils.uuid(),
          icon: "mdi-account-group",
          name: "账户管理",
          active: false,
          path: "/system/account",
        },
        {
          key: utils.uuid(),
          icon: "mdi-math-log",
          name: "系统日志",
          active: false,
          path: "/system/log",
        },
        {
          key: utils.uuid(),
          icon: "mdi-cog",
          name: "系统设置",
          active: false,
          username: "root",
          path: "/system/setting",
        },
      ],
    },
    {
      key: utils.uuid(),
      icon: "mdi-account-cog",
      name: "个人中心",
      active: false,
      roles: [],
      children: [
        {
          key: utils.uuid(),
          icon: "mdi-account",
          name: "账户设置",
          active: false,
          roles: [],
          path: "/user/setting",
        },
      ],
    },
  ],
  rolecolors: {
    "root": "#F00",
    "admin": "#F08",
    "gm": "#F0F",
    "pm": "#F80",
    "caiwu": "#F88",
    "kuaiji": "#F8F",
    "chuna": "#8F8",
    "pm2": "#08F",
    "jiesuan": "#800",
    "henan": "#808",
    "guangdong": "#80F",
    "jiangxi": "#880",
    "zhejiang": "#88F",
    "guangxi": "#8F0",
  },
  rolenames: {
    root: "超级管理员",
    admin: "管理员",
    gm: "总经理",
    pm: "业务分管领导",
    pm2: "项目经理",
    caiwu: "主管财务",
    kuaiji: "会计",
    chuna: "出纳",
    jiesuan: "结算",
    henan: "河南驻点",
    guangdong: "广东驻点",
    jiangxi: "江西驻点",
    zhejiang: "浙江驻点",
    guangxi: "广西驻点",
  },
  roles: [
    { text: "总经理", value: "gm" },
    { text: "业务分管领导", value: "pm" },
    { text: "项目经理", value: "pm2" },
    { text: "主管财务", value: "caiwu" },
    { text: "会计", value: "kuaiji" },
    { text: "出纳", value: "chuna" },
    { text: "结算", value: "jiesuan" },
    { text: "河南驻点", value: "henan" },
    { text: "广东驻点", value: "guangdong" },
    { text: "江西驻点", value: "jiangxi" },
    { text: "浙江驻点", value: "zhejiang" },
    { text: "广西驻点", value: "guangxi" },
  ],
  roles2: [
    { text: "管理员", value: "admin" },
    { text: "总经理", value: "gm" },
    { text: "业务分管领导", value: "pm" },
    { text: "项目经理", value: "pm2" },
    { text: "主管财务", value: "caiwu" },
    { text: "会计", value: "kuaiji" },
    { text: "出纳", value: "chuna" },
    { text: "结算", value: "jiesuan" },
    { text: "河南驻点", value: "henan" },
    { text: "广东驻点", value: "guangdong" },
    { text: "江西驻点", value: "jiangxi" },
    { text: "浙江驻点", value: "zhejiang" },
    { text: "广西驻点", value: "guangxi" },
  ],
  allroles: [
    { text: "超级管理员", value: "root" },
    { text: "管理员", value: "admin" },
    { text: "总经理", value: "gm" },
    { text: "业务分管领导", value: "pm" },
    { text: "项目经理", value: "pm2" },
    { text: "主管财务", value: "caiwu" },
    { text: "会计", value: "kuaiji" },
    { text: "出纳", value: "chuna" },
    { text: "结算", value: "jiesuan" },
    { text: "河南驻点", value: "henan" },
    { text: "广东驻点", value: "guangdong" },
    { text: "江西驻点", value: "jiangxi" },
    { text: "浙江驻点", value: "zhejiang" },
    { text: "广西驻点", value: "guangxi" },
  ],
  avatar: "",
  userid: "",
  username: "",
  name: "",
  role: "",
  navTitle: "",
  navLogo: "",
  navTabs: [],
  navTabIndex: -1,
  uploadtask_title: "",
  uploadtask_model: false,
  uploadtask_type: "",
}
function checkRoles(roles, role) {
  return !_.isArray(roles) || roles.length === 0 || roles.indexOf(role) > -1;
}
const getters = {
  rolename: (state) => () => {
    return state.rolenames[state.role];
  },
  getmenus: (state) => (path) => {
    let menus = [];
    for (let i = 0; i < state.initmenus.length; i++) {
      let menu = { ...state.initmenus[i] };
      if (checkRoles(menu.roles, state.role) === false) continue;
      if (menu.username && menu.username !== state.username) continue;
      if (menu.children) {
        menu.active = !!_.findWhere(menu.children, { path });
        let children = [];
        for (let j = 0; j < menu.children.length; j++) {
          let cmenu = menu.children[j];
          if (checkRoles(cmenu.roles, state.role) === false) continue;
          if (cmenu.username && cmenu.username !== state.username) continue;
          cmenu.active = cmenu.path === path;
          children.push({ ...cmenu });
        }
        menu.children = children;
        if (menu.children.length > 0) menus.push({ ...menu })
      } else {
        menu.active = menu.path === path;
        menus.push({ ...menu });
      }
    }
    return menus;
  },
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});
