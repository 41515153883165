<template>
  <v-container fluid>
    <a-form-model layout="vertical" :hideRequiredMark="true">
      <a-row :gutter="16">
        <a-col :lg="2" :md="4">
          <a-form-model-item label="项目类型">
            <a-select show-search v-model="queryForm.tasktype" placeholder="项目类型" @change="getdata">
              <a-select-option key="1"> 普通项目 </a-select-option>
              <a-select-option key="2"> 南宁项目 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :lg="2" :md="4">
          <a-form-model-item label="运行状态">
            <a-select show-search v-model="queryForm.running" placeholder="运行状态" @change="getdata">
              <a-select-option :key="0"> 全部 </a-select-option>
              <a-select-option :key="1"> 运行 </a-select-option>
              <a-select-option :key="2"> 停止 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :lg="2" :md="4">
          <a-form-model-item label="抓取类型">
            <a-select show-search v-model="queryForm.type" placeholder="抓取类型" @change="getdata">
              <a-select-option key="all"> 全部 </a-select-option>
              <a-select-option key="permanent"> 永久 </a-select-option>
              <a-select-option key="temporary"> 临时 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :lg="4" :md="6">
          <a-form-model-item label="邮路名称">
            <a-input v-model="queryForm.query_c_ylmc" placeholder="邮路名称" allowClear @keyup.enter="getdata" />
          </a-form-model-item>
        </a-col>
        <a-col :lg="4" :md="6">
          <a-form-model-item label="派车响应单位">
            <a-input v-model="queryForm.query_c_pcdwmc_xy" placeholder="派车响应单位" allowClear @keyup.enter="getdata" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <v-card tile class="mt-2">
      <a-table size="small" bordered :columns="headers" :data-source="list" :pagination="false"
        :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex justify-space-between">
            <a-space class="pl-2">
              <v-btn tile small class="error white--text" @click="showadd">
                <v-icon small>mdi-plus</v-icon>添加
              </v-btn>
              <v-btn tile small class="primary white--text" @click="getdata">
                <v-icon small>mdi-refresh</v-icon>刷新
              </v-btn>
            </a-space>
            <a-space>
              <a-input v-model="config.Cookie" placeholder="Cookie" style="width: 400px">
              </a-input>
              <a-popconfirm title="设置Cookiet" ok-text="确定" cancel-text="取消" @confirm="setconfig">
                <v-btn tile small class="success white--text">
                  <v-icon small>mdi-update</v-icon>设置
                </v-btn>
              </a-popconfirm>
            </a-space>
          </div>
        </template>
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="customA">
          <p class="success--text">开始时间</p>
          <p class="error--text">结束时间</p>
        </template>
        <template slot="A" slot-scope="text, record">
          <p class="success--text">{{ record.start_sj }}</p>
          <p class="error--text">{{ record.end_sj }}</p>
        </template>
        <template slot="customB">
          <p>邮路名称</p>
          <p>邮路级别</p>
        </template>
        <template slot="B" slot-scope="text, record">
          <p>{{ record.query_c_ylmc }}</p>
          <p>{{ record.query_n_yljb }}</p>
        </template>
        <template slot="type" slot-scope="text, record">
          <span class="error--text" v-if="record.type === 'permanent'">永久</span>
          <span class="primary--text" v-if="record.type === 'temporary'">临时</span>
        </template>
        <template slot="queryAction" slot-scope="text, record">
          {{ getqueryaction(record) }}
        </template>
        <template slot="running" slot-scope="text, record">
          <a-switch v-model="record.running" checked-children="运行" un-checked-children="停止"
            @change="changestate($event, record)" />
        </template>
        <template slot="runtime" slot-scope="text, record">
          <p class="success--text">{{ record.last_runtime }}</p>
          <p class="error--text">{{ record.next_runtime }}</p>
        </template>
        <template slot="action" slot-scope="text, record">
          <div>
            <a-space>
              <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
              <a-popconfirm title="删除抓取任务" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
                <v-btn text tile color="error"> 删除 </v-btn>
              </a-popconfirm>
            </a-space>
          </div>
          <div>
            <v-btn text tile color="error" @click="showcopy(record)"> 复制 </v-btn>
            <a-space>
              <v-btn v-if="username === 'root'" text tile color="orange" @click="runonce(record)"> 单次执行 </v-btn>
            </a-space>
          </div>
        </template>
      </a-table>
    </v-card>
    <v-dialog v-model="model" width="800">
      <a-card title="路单任务">
        <a-space slot="extra">
          <a-checkbox v-model="continue_add">连续添加</a-checkbox>
          <a-button type="danger" :loading="saving" :disabled="saving" @click="save"> 提交保存
          </a-button>
          <a-button @click="model = false"> 取消
          </a-button>
        </a-space>
        <a-form-model ref="formRef" :model="form" layout="vertical">
          <a-row :gutter="16">
            <a-col :lg="6" :md="8">
              <a-form-model-item label="项目类型" prop="tasktype">
                <a-select show-search v-model="form.tasktype" :disabled="saving || form._id !== ''">
                  <a-select-option key="1"> 普通项目 </a-select-option>
                  <a-select-option key="2"> 南宁项目 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="任务类型" prop="type">
                <a-select show-search v-model="form.type" :disabled="saving || form._id !== ''">
                  <a-select-option key="permanent"> 永久 </a-select-option>
                  <a-select-option key="temporary"> 临时 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :lg="6" :md="8">
              <a-form-model-item label="查询方式" prop="queryAction">
                <a-select show-search v-model="form.queryAction" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.queryActions" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="12" :md="12">
              <a-form-model-item label="查询时间" prop="days">
                <a-range-picker v-model="form.days" :locale="locale" show-time format="YY-MM-DD HH:mm:ss"
                  valueFormat="YY-MM-DD HH:mm:ss" :disabled="saving" style="width:100%;" />
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="邮路名称" prop="query_c_ylmc">
                <a-input v-model="form.query_c_ylmc" placeholder="邮路名称" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="车牌号" prop="query_c_cph">
                <a-input v-model="form.query_c_cph" placeholder="车牌号" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="任务状态" prop="query_n_rwzt">
                <a-select show-search v-model="form.query_n_rwzt" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_rwzt" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="GNSS" prop="query_sbzxzt">
                <a-select show-search v-model="form.query_sbzxzt" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_rwzt" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="运行准点" prop="query_n_rwzdzt">
                <a-select show-search v-model="form.query_n_rwzdzt" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_rwzdzt" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="邮路级别" prop="query_n_yljb">
                <a-select show-search v-model="form.query_n_yljb" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_yljb" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="自委办标志" prop="query_n_clzwbbz">
                <a-select show-search v-model="form.query_n_clzwbbz" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_clzwbbz" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="驾驶员姓名" prop="query_c_zjxm">
                <a-input v-model="form.query_c_zjxm" placeholder="驾驶员姓名" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="异常事件" prop="query_n_sfcz_ycsj">
                <a-select show-search v-model="form.query_n_sfcz_ycsj" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_sfcz_ycsj" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="派车响应单位" prop="query_c_pcdwmc_xy">
                <a-input v-model="form.query_c_pcdwmc_xy" placeholder="派车响应单位" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="运力类型" prop="query_n_yllx">
                <a-select show-search v-model="form.query_n_yllx" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_yllx" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="派车单号" prop="query_c_pcdh">
                <a-input v-model="form.query_c_pcdh" placeholder="派车单号" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="已电子化" prop="query_n_sfydzh">
                <a-select show-search v-model="form.query_n_sfydzh" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_sfydzh" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="结算属性" prop="query_n_yzhsd">
                <a-select show-search v-model="form.query_n_yzhsd" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_yzhsd" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="派班性质" prop="query_n_pbxz">
                <a-select show-search v-model="form.query_n_pbxz" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_pbxz" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="异常原因类型" prop="query_n_ycyylx">
                <a-select show-search v-model="form.query_n_ycyylx" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_ycyylx" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="位置来源" prop="query_n_wzly">
                <a-select show-search v-model="form.query_n_wzly" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_wzly" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="派押局" prop="query_c_pyjmc">
                <a-input v-model="form.query_c_pyjmc" placeholder="派押局" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="始发局" prop="query_c_sfjmc">
                <a-input v-model="form.query_c_sfjmc" placeholder="始发局" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="终到局" prop="query_c_zdjmc">
                <a-input v-model="form.query_c_zdjmc" placeholder="终到局" :disabled="saving" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="派车是否一致" prop="query_vitrul_pcsfyz">
                <a-select show-search v-model="form.query_vitrul_pcsfyz" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.vitrul_pcsfyz" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="人脸识别" prop="query_n_rlsbyw">
                <a-select show-search v-model="form.query_n_rlsbyw" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_rlsbyw" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="8">
              <a-form-model-item label="认证结果" prop="query_n_rlsb_sfbr">
                <a-select show-search v-model="form.query_n_rlsb_sfbr" :disabled="saving">
                  <a-select-option v-for="item in cptmcp.n_rlsb_sfbr" :key="item.id"> {{ item.cn }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { mapState } from "vuex";
import UploadTask from "@/components/uploadtask.vue";
export default {
  name: "OrderList",
  data() {
    return {
      locale,
      config: { Cookie: "" },
      queryForm: { tasktype: "1", running: 1, type: "permanent", query_c_ylmc: "", query_c_pcdwmc_xy: "" },
      cptmcp: {
        queryActions: [], n_rwzt: [], sbzxzt: [], n_rwzdzt: [], n_yljb: [], n_clzwbbz: [], n_sfcz_ycsj: [], n_yllx: [],
        n_sfydzh: [], n_yzhsd: [], n_pbxz: [], n_ycyylx: [], n_wzly: [], vitrul_pcsfyz: [], n_rlsbyw: [], n_rlsb_sfbr: [],
      },
      form: {},
      saving: false,
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 80, align: "center" },
        { title: "抓取类型", dataIndex: "type", scopedSlots: { customRender: "type" }, width: 100, align: "center" },
        { title: "查询方式", dataIndex: "queryAction", scopedSlots: { customRender: "queryAction" }, width: 120, align: "center" },
        { dataIndex: "A", slots: { title: "customA" }, scopedSlots: { customRender: "A" }, width: 150, align: "center" },
        // { title: "开始时间", dataIndex: "start_sj", scopedSlots: { customRender: "start_sj" }, width: 180, align: "center" },
        // { title: "结束时间", dataIndex: "end_sj", scopedSlots: { customRender: "end_sj" }, width: 180, align: "center" },
        { dataIndex: "B", slots: { title: "customB" }, scopedSlots: { customRender: "B" }, width: 150, align: "center" },
        // { title: "邮路名称", dataIndex: "query_c_ylmc", scopedSlots: { customRender: "query_c_ylmc" }, width: 150, align: "center" },
        // { title: "邮路级别", dataIndex: "query_n_yljb", scopedSlots: { customRender: "query_n_yljb" }, width: 120, align: "center" },
        { title: "派车响应单位", dataIndex: "query_c_pcdwmc_xy", scopedSlots: { customRender: "query_c_pcdwmc_xy" }, width: 200, align: "center" },
        { title: "运行状态", dataIndex: "running", scopedSlots: { customRender: "running" }, width: 120, align: "center" },
        { title: "运行时间", dataIndex: "runtime", scopedSlots: { customRender: "runtime" }, width: 150, align: "center" },
        { title: "抓取总数", dataIndex: "count", scopedSlots: { customRender: "count" }, width: 100, align: "center" },
        { title: "最近抓取数", dataIndex: "last_count", scopedSlots: { customRender: "last_count" }, width: 100, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 200, align: "center", fixed: 'right' },
      ],
      list: [],
      height: 600,
      model: false,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      continue_add: false,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: { UploadTask },
  watch: {
  },
  mounted() {
    this.height = window.innerHeight - 300;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 300;
    };
  },
  activated() {
    this.list = [];
    this.getdata();
  },
  methods: {
    setconfig() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/setconfig", this.config).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$toast.success("设置成功");
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/list", this.queryForm).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.list = res.data.list;
          this.config = res.data.config;
          this.cptmcp = res.data.cptmcp;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showadd() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/info").then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.cptmcp = res.data.cptmcp;
          this.form = res.data.form;
          this.model = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showedit(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/info", { _id: item._id }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.cptmcp = res.data.cptmcp;
          this.form = res.data.form;
          this.model = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showcopy(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/info", { _id: item._id }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.cptmcp = res.data.cptmcp;
          this.form = { ...res.data.form, _id: "" };
          this.model = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    runonce(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/info", { _id: item._id }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.cptmcp = res.data.cptmcp;
          this.form = {
            ...res.data.form,
            _id: "",
            type: "temporary",
            days: [this.$utils.dateFormat(new Date(), 'YY-MM-01 00:00:00'), this.$utils.dateFormat(new Date(), 'YY-MM-DD 23:59:59')]
          };
          this.model = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    save() {
      this.$toast.loading({ message: "" });
      this.saving = true;
      this.$api.doAPI("/cptmcp/save", { form: this.form }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        this.saving = false;
        if (res.code === 0) {
          this.model = this.continue_add;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.saving = false;
        this.$toast.clear();
        console.error(err);
      });
    },
    changestate(running, item) {
      console.log('changestate', running, item);
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/changestate", { _id: item._id, running }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    getqueryaction(item) {
      let queryaction = _.findWhere(this.cptmcp.queryActions, { value: item.queryAction });
      return queryaction?.label;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: unset;
}

p {
  margin: 0;
}
</style>
