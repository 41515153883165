<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <a-range-picker v-model="queryForm.months" :mode="mode" :placeholder="['开始月份', '结束月份']" :locale="locale"
          :allowClear="false" format="YYYY-MM" valueFormat="YYYY-MM" size="large" style="width:100%;"
          @change="changeMonth" @panelChange="changeMonthPanel" />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.province" :items="provinces" label="省份"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <v-btn @click="getdata" color="cyan" dark tile>
          <v-icon small>mdi-magnify</v-icon>查询
        </v-btn>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between align-center mt-2 mb-2">
      <v-btn @click="add" color="success" dark tile>
        <v-icon small>mdi-plus</v-icon>新增成本
      </v-btn>
      <v-btn @click="download" color="indigo" dark tile>
        <v-icon small>mdi-download</v-icon>下载账单
      </v-btn>
    </div>
    <v-card tile>
      <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false"
        :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }"
        :row-selection="{ selectedRowKeys, onChange: changeSelectedRows }">
        <template slot="title">
          <div class="d-flex justify-end">
            <div class="d-flex align-center pl-2">
              <div class="pages">
                <span>共 {{ length }} 页 / {{ count }} 条数据</span>
                <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                      {{ limit }}/页
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                      <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
            </div>
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="billModel" persistent max-width="600px">
      <v-form ref="billForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ billForm._id ? '编辑' : '新增' }}项目成本
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete :items="provinces" v-model="billForm.province" :rules="[vrules.required]"
                    :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                    <template v-slot:label>
                      项目 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false"
                    :return-value.sync="billForm.month" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="billForm.month" :rules="[vrules.required]" placeholder="选择月份"
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          月份<span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="billForm.month" type="month" no-title value="YYYY-mm" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.month_menu.save(billForm.month); month_menu = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="billForm.feetype" :rules="[vrules.required]" outlined dense>
                    <template v-slot:label>
                      费用科目<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="billForm.amount" type="number" :rules="[vrules.required]" outlined dense>
                    <template v-slot:label>
                      金额<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="billForm.feeremark" rows="2" outlined>
                    <template v-slot:label>
                      费用事由
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="billForm.name" outlined dense>
                    <template v-slot:label>
                      收款人姓名
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="billForm.phone" outlined dense>
                    <template v-slot:label>
                      收款人电话
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="billForm.bankname" outlined dense>
                    <template v-slot:label>
                      开户行
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="billForm.bankaccount" outlined dense>
                    <template v-slot:label>
                      收款账号
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="billForm.remark" label="备注" rows="2" outlined>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" :disabled="!valid" @click="save">保存</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

export default {
  name: "FinanceProjectBill",
  data() {
    return {
      pages: [
        { text: "20/页", value: 20 },
        { text: "50/页", value: 50 },
        { text: "100/页", value: 100 },
        { text: "200/页", value: 200 },
        { text: '500/页', value: 500 },
        { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      locale,
      mode: ['month', 'month'],
      queryForm: {
        months: [this.$utils.moment().format('YYYY-MM'), this.$utils.moment().format('YYYY-MM')],
        province: "",
      },
      provinces: [],
      feetypes: [],
      headers: [
        { title: "所属项目", dataIndex: "province", scopedSlots: { customRender: "province" }, width: 120, align: "center", },
        { title: "月份", dataIndex: "month", scopedSlots: { customRender: "month" }, width: 120, align: "center", },
        { title: "费用科目", dataIndex: "feetype", scopedSlots: { customRender: "feetype" }, width: 120, align: "center", },
        { title: "费用事由", dataIndex: "feeremark", scopedSlots: { customRender: "feeremark" }, width: 150, align: "center", },
        { title: "金额", dataIndex: "amount", scopedSlots: { customRender: "amount" }, width: 120, align: "center", },
        { title: "收款人姓名", dataIndex: "name", scopedSlots: { customRender: "name" }, width: 120, align: "center", },
        { title: "收款人电话", dataIndex: "phone", scopedSlots: { customRender: "phone" }, width: 120, align: "center", },
        { title: "开户行", dataIndex: "bankname", scopedSlots: { customRender: "bankname" }, width: 150, align: "center", },
        { title: "收款账号", dataIndex: "bankaccount", scopedSlots: { customRender: "bankaccount" }, width: 150, align: "center", },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 150, align: "center", },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: "right", },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      billForm: {},
      billModel: false,
      valid: true,
      month_menu: false,
      selectedRowKeys: [],
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 380;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 380;
    };
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.getdata();
  },
  methods: {
    changeMonth(value) {
      this.queryForm.months = [value[0].format('YYYY-MM'), value[1].format('YYYY-MM')];
    },
    changeMonthPanel(value, mode) {
      this.queryForm.months = [value[0].format('YYYY-MM'), value[1].format('YYYY-MM')];
      this.mode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/projectbill/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = _.map(res.data.list, n => {
            return {
              ...n,
            }
          });
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
          this.provinces = res.data.provinces;
          this.feetypes = res.data.feetypes;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/projectbill/list", { ...this.queryForm, action: 'download' }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    changeSelectedRows(keys) {
      let list = _.filter(this.list, n => keys.indexOf(n._id) > -1);
      this.selectedRowKeys = _.pluck(list, "_id");
      console.log(this.selectedRowKeys);
    },
    createForm() {
      return {
        _id: "",
        province: "",
        month: "",
        feetype: "",
        feeremark: "",
        amount: "",
        name: "",
        phone: "",
        bankname: "",
        bankaccount: "",
        remark: "",
      };
    },
    add() {
      this.billForm = this.createForm();
      this.billModel = true;
    },
    edit(item) {
      this.billForm = _.pick(item, _.keys(this.createForm()));
      this.billModel = true;
    },
    cancelsave() {
      this.$refs.billForm.resetValidation();
      this.billModel = false;
    },
    save() {
      if (!this.$refs.billForm.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/projectbill/save", { form: this.billForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          message.success("保存成功");
          this.billModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}

p {
  margin: 0;
}
</style>
