<template>
  <v-container fluid>
    <a-space>
      <v-btn color="primary" tile @click="getdata">刷新</v-btn>
      <v-btn color="success" tile @click="add">添加</v-btn>
    </a-space>
    <v-card>
      <v-card-text>
        <v-data-table :headers="[{ text: '操作', value: 'action', width: 120 },...headers]" :items="list"
          :items-per-page="limit" no-data-text="无数据" hide-default-footer disable-sort fixed-header height="600">
          <template v-slot:item.action="{ item }">
            <a-space>
              <v-btn tile text color="primary" @click="edit(item)">编辑</v-btn>
              <v-btn tile text color="success" @click="qrcode(item)">二维码</v-btn>
            </a-space>
          </template>
        </v-data-table>
        <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
      </v-card-text>
    </v-card>
    <v-dialog v-model="model" persistent max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container style="max-height:600px;overflow-y: auto;">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-for="item in headers" :key="item.value" v-model="form[item.value]"
                    :rules="[vrules.required]" :placeholder="item.text" dense outlined>
                    <template v-slot:label>
                      {{ item.text }} <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!valid" @click="save">确定</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="qrmodel" persistent max-width="600px">

    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { message } from "ant-design-vue";
export default {
  data() {
    return {
      headers: [
        { text: "证书组织名称", value: "certificate_org_name", width: 200 },
        { text: "证书编号", value: "certificate_no", width: 150 },
        { text: "注册时间", value: "initial_issue_date", width: 120 },
        { text: "到期时间", value: "valid_until_date", width: 120 },
        { text: "证书范围", value: "certificate_scope", width: 200 },
        { text: "认证依据", value: "certification_basis", width: 200 },
        { text: "认证标志", value: "certification_mark", width: 120 },
        { text: "证书状态", value: "certificate_status", width: 120 },
        { text: "注册地址", value: "registered_address", width: 200 },
        { text: "办公(审核)地址", value: "business_address", width: 200 },
        { text: "经营/生产地址", value: "production_address", width: 200 },
        { text: "颁证机构", value: "awarding_bodies", width: 200 },
        { text: "机构批准号", value: "institutional_approval_number", width: 150 },
      ],
      list: [],
      page: 1,
      limit: 20,
      length: 0,
      form: {},
      model: false,
      valid: true,
      vrules: {
        required: (value) => !!value || "不能为空",
      },
      modeltitle: '',
      qrmodel: false,
      qrurl: '',
    };
  },
  components: {},
  computed: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  activated() { },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cert/list", { page: this.page, limit: this.limit }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    add() {
      this.modeltitle = "添加证书";
      this.form = {
        _id: "",
        ..._.reduce(this.headers, (a, b) => _.extend(a, { [b.value]: "" }), {}),
        certificate_org_name: '河南利滴物流有限公司',
        certificate_no: '135424E0074R0S',
        initial_issue_date: '2024-09-14',
        valid_until_date: '2027-09-13',
        certificate_scope: '许可范围内道路普通货物运输 及相关管理活动',
        certification_basis: 'GB/T24001-2016/ISO14001:2015',
        certification_mark: '无',
        certificate_status: '有效',
        registered_address: '河南自贸试验区郑州片区（郑东）寿丰街50号21楼2114号',
        business_address: '河南自贸试验区郑州片区（郑东）寿丰街50号21楼2114号',
        production_address: '河南自贸试验区郑州片区（郑东）寿丰街50号21楼2114号',
        awarding_bodies: '万鼎认证（河南）有限公司',
        institutional_approval_number: 'CNCA-R-2024-1354',
      };
      this.model = true;
    },
    edit(item) {
      this.modeltitle = "编辑证书";
      this.form = {
        _id: item._id,
        ..._.reduce(this.headers, (a, b) => _.extend(a, { [b.value]: item[b.value] || "" }), {}),
      };
      this.model = true;
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cert/save", { form: this.form }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          message.success("保存成功");
          this.model = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsave() {
      this.model = false;
    },
    qrcode(item) {

    },
  },
};
</script>
<style scoped lang="less"></style>
