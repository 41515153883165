<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <v-text-field v-model="queryForm.number" label="车牌号" outlined dense clearable hide-details />
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <a-space>
          <v-btn color="cyan" tile style="color:#fff;" @click="getdata">
            <v-icon small>mdi-magnify</v-icon> 查询
          </v-btn>
          <v-btn color="primary" tile @click="showadd">
            <v-icon small>mdi-plus</v-icon> 新增车辆黑名单
          </v-btn>
        </a-space>
      </v-col>
    </v-row>
    <v-card tile class="mt-2">
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex align-center pl-2">
            <span>共 {{ length }} 页 / {{ count }} 条数据</span>
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                  {{ limit }}/页
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                  <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="create_time" slot-scope="text, record">
          {{ record.create_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a-popconfirm title="删除黑名单" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
              <v-btn text tile color="error"> 删除 </v-btn>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="model" persistent max-width="400px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model="numbers" :rules="[vrules.required]" placeholder="输入车牌号，每行一个"
                    persistent-placeholder outlined rows="5" dense>
                    <template v-slot:label>
                      车牌号 <span class="red--text">*</span>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!valid" @click="save">确定</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "VehicleList",
  data() {
    return {
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        { text: '200/页', value: 200 },
        // { text: '500/页', value: 500 },
        // { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      queryForm: { number: "", gnumber: "", type: "", },
      vehicle_types: [],
      valid: true,
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 80, align: "center" },
        { title: "车牌号", dataIndex: "number", scopedSlots: { customRender: "number" }, width: 120, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
        { title: "创建人", dataIndex: "create_by", scopedSlots: { customRender: "create_by" }, width: 150, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      model: false,
      modeltitle: "",
      vrules: {
        required: (value) => !!value || "不能为空",
      },
      numbers: "",
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehicleblock/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showadd() {
      this.numbers = "";
      this.modeltitle = "新增车辆黑名单";
      this.model = true;
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehicleblock/save", { numbers: this.numbers }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.model = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsave() {
      this.$refs.form.resetValidation();
      this.model = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehicleblock/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
