<template>
  <v-container fluid>
    <a-form-model layout="inline">
      <a-form-model-item label="统计日期">
        <a-range-picker v-model="queryForm.times" :locale="locale" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
          :allow-clear="false" />
      </a-form-model-item>
      <a-form-model-item label="邮路名称">
        <a-input v-model="queryForm.ylmc" placeholder="邮路名称" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="组开省">
        <a-input v-model="queryForm.zks" placeholder="组开省" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="组开局">
        <a-input v-model="queryForm.zkj" placeholder="组开局" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="始发省">
        <a-input v-model="queryForm.sfs" placeholder="始发省" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="始发局">
        <a-input v-model="queryForm.sfj" placeholder="始发局" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="终到省">
        <a-input v-model="queryForm.zds" placeholder="终到省" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="终到局">
        <a-input v-model="queryForm.zdj" placeholder="终到局" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="邮路级别">
        <a-select v-model="queryForm.yljb" :options="yljbs" placeholder="邮路级别" mode="multiple" style="width:200px;">
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-space>
          <a-button type="danger" @click="createtask">
            请求统计结果
          </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
    <a-button type="primary" @click="gettask">刷新</a-button>
    <v-card tile class="mt-2">
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center pl-2">
              <span>共 {{ length }} 页 / {{ count }} 条数据</span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                    {{ limit }}/页
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
          </div>
        </template>
        <template slot="times" slot-scope="text, record">
          {{ record.times[0] }} ~ {{ record.times[1] }}
        </template>
        <template slot="state" slot-scope="text, record">
          <a-tag v-if="record.state === 'fail'" color="#f50">
            失败
          </a-tag>
          <a-tag v-if="record.state === 'process'" color="#2db7f5">
            正在统计
          </a-tag>
          <a-tag v-if="record.state === 'success'" color="#87d068">
            成功
          </a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a v-if="record.state === 'success'" href="javascript:;" class="success--text"
              @click="downloadstatistics(record._id)">下载统计结果</a>
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
  </v-container>
</template>

<script>
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { mapState } from "vuex";
export default {
  name: "OrderList",
  data() {
    return {
      locale,
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        // { text: '200/页', value: 200 },
      ],
      queryForm: {
        times: [null, null],
        ylmc: "", zks: "", zkj: "", sfs: "", sfj: "",
        zds: "", zdj: "", yljb: ["1", "2"],
      },
      yljbs: [
        { label: '一干', value: '1' },
        { label: '二干', value: '2' },
        { label: '市趟', value: '3' },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      headers: [
        { title: "统计日期", dataIndex: "times", scopedSlots: { customRender: "times" }, width: 220, align: "center" },
        { title: "邮路名称", dataIndex: "ylmc", scopedSlots: { customRender: "ylmc" }, width: 120, align: "center" },
        { title: "组开省", dataIndex: "zks", scopedSlots: { customRender: "zks" }, width: 120, align: "center" },
        { title: "组开局", dataIndex: "zkj", scopedSlots: { customRender: "zkj" }, width: 120, align: "center" },
        { title: "始发省", dataIndex: "sfs", scopedSlots: { customRender: "sfs" }, width: 120, align: "center" },
        { title: "始发局", dataIndex: "sfj", scopedSlots: { customRender: "sfj" }, width: 120, align: "center" },
        { title: "终到省", dataIndex: "zds", scopedSlots: { customRender: "zds" }, width: 120, align: "center" },
        { title: "终到局", dataIndex: "zdj", scopedSlots: { customRender: "zdj" }, width: 120, align: "center" },
        { title: "邮路级别", dataIndex: "yljb", scopedSlots: { customRender: "yljb" }, width: 120, align: "center" },
        { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 100, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 180, align: "center", fixed: 'right' }
      ],
      vrules: {
        required: (value) => !!value || "不能为空"
      },
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.gettask();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.gettask();
    },
  },
  mounted() {
    this.height = window.innerHeight - 400;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 400;
    };
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.queryForm = {
      times: [this.$dateFormat(new Date(), 'YYYY-MM-01'), this.$dateFormat()],
      ylmc: "", zks: "", zkj: "", sfs: "", sfj: "",
      zds: "", zdj: "", yljb: ["1", "2"],
    }
    this.gettask();
  },
  methods: {
    createtask() {
      let [start, end] = this.queryForm.times;
      start = new Date(`${start} 00:00:00.000+08:00`);
      end = new Date(`${end} 23:59:59.999+08:00`);
      if ((end - start) > 31 * 24 * 60 * 60 * 1000) return this.$alert("日期暂时不能超过31天");
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/transport_createtask", { ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.gettask();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    gettask() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/transport_tasklist", { page: this.page, limit: this.limit }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.list = _.map(res.data.list, n => {
            return {
              ...n,
              yljb: _.map(n.yljb, m => _.findWhere(this.yljbs, { value: m })?.label || "").join(','),
              create_time: this.$dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
              update_time: n.update_time ? this.$dateFormat(n.update_time, 'YYYY-MM-DD HH:mm:ss') : "",
            }
          });
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    downloadstatistics(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/transport_download", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: unset;
}

/deep/ .row+.row {
  margin-top: 0;
}

p {
  margin: 0;
}
</style>
