<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-textarea v-model="queryForm.nos" outlined rows="4" label="派车单号/流水号"></v-textarea>
      </v-col>
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-autocomplete v-model="queryForm.day_type"
              :items="[{ text: '对账日期', value: 'combine_t' }, { text: '流水号日期', value: 'serial_t' }, { text: '封车日期', value: 'block_t' }]"
              label="查询方式" :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-menu ref="start_day_menu" v-model="start_day_menu" :close-on-content-click="false"
              :return-value.sync="queryForm.start_day" transition="scale-transition" min-width="auto" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="queryForm.start_day" label="开始日期" append-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" dense outlined>
                  <template v-slot:label>开始日期</template>
                </v-text-field>
              </template>
              <v-date-picker v-model="queryForm.start_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
                @input="() => { $refs.start_day_menu.save(queryForm.start_day); start_day_menu = false; }"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-menu ref="end_day_menu" v-model="end_day_menu" :close-on-content-click="false"
              :return-value.sync="queryForm.end_day" transition="scale-transition" min-width="auto" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="queryForm.end_day" label="结束日期" append-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" dense outlined>
                  <template v-slot:label>结束日期</template>
                </v-text-field>
              </template>
              <v-date-picker v-model="queryForm.end_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
                @input="() => { $refs.end_day_menu.save(queryForm.end_day); end_day_menu = false; }"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-autocomplete v-model="queryForm.province" :items="provinces" label="省份"
              :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="4">
            <v-text-field v-model="queryForm.vehicle_number" label="车牌号" outlined dense clearable>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="4">
            <v-autocomplete v-model="queryForm.postlineids" :items="postlines" label="线路"
              :menu-props="{ bottom: true, offsetY: true }" dense outlined clearable no-data-text="无" multiple>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="queryForm.postlineids.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      全选
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.label }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ queryForm.postlineids.length - 1 }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="4">
            <v-autocomplete v-model="queryForm.self_bill_state" :items="self_bill_states" label="状态"
              :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable>
            </v-autocomplete>
          </v-col>
          <!-- <v-col cols="12" md="2" sm="4">
            <v-checkbox v-model="queryForm.use_date" label="使用日期">
            </v-checkbox>
          </v-col> -->
          <v-col cols="12" lg="2" md="4" sm="6">
            <a-space>
              <v-btn @click="getdata" color="cyan" dark tile small>
                <v-icon small>mdi-magnify</v-icon>查询
              </v-btn>
              <v-btn @click="download" color="indigo" dark tile small>
                <v-icon small>mdi-download</v-icon>下载自营账单
              </v-btn>
              <UploadTask btnText="上传自营账单" modelTitle="上传自营账单" btnSize="small" uploadType="selfbill" />
              <v-btn @click="download2" color="purple" dark tile small>
                <v-icon small>mdi-download</v-icon>下载合并账单
              </v-btn>
            </a-space>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <p v-if="total_freight" class="error--text text-h6">
      合计：
      <span> 平台总价
        <span class="success--text">{{ total_freight.real_freight2 }}</span>
        ({{ total_freight.real_freight2_947 }})
      </span>,
      <span> 油卡金额
        <span class="success--text">{{ total_freight.oilcard_fee }}</span>
      </span>,
      <span> 卸货到付
        <span class="success--text">{{ total_freight.delivery_discharge_fee }}</span>
        ({{ total_freight.delivery_discharge_fee_947 }})
      </span>,
      <span> 回单押金
        <span class="success--text">{{ total_freight.receipt_deposit }}</span>
        ({{ total_freight.receipt_deposit_947 }})
      </span>,
      <span> 考核 ({{ total_freight.check_amount2 }}) </span>
    </p>
    <v-card tile>
      <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false"
        :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }"
        :row-selection="{ selectedRowKeys, onChange: changeSelectedRows }">
        <template slot="title">
          <div class="d-flex justify-space-between">
            <a-button type="primary" @click="makepayable">
              生成应付账款
            </a-button>
            <div class="d-flex align-center pl-2">
              <div class="pages">
                <span>共 {{ length }} 页 / {{ count }} 条数据</span>
                <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                      {{ limit }}/页
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                      <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
            </div>
          </div>
        </template>
        <template slot="customA">
          <p>流水号日期</p>
          <p>封车日期</p>
        </template>
        <template slot="customB">
          <p>班次</p>
          <p>邮路名称</p>
          <p>始发→到达</p>
        </template>
        <template slot="customD">
          <p>车牌号</p>
          <p>挂厢号</p>
        </template>
        <template slot="customE">
          <p>车长</p>
          <p>吨位</p>
        </template>
        <template slot="customF">
          <p>司机姓名</p>
          <p>司机电话</p>
        </template>
        <template slot="customG">
          <p>封车时间</p>
          <p>发车时间</p>
          <p>到达时间</p>
        </template>
        <template slot="customI">
          <p>流水号</p>
          <p>派车单号</p>
        </template>
        <template slot="customJ">
          <p>平台</p>
          <p>平台单号</p>
        </template>
        <template slot="A" slot-scope="text, record">
          <p>{{ record.serial_day }}</p>
          <p>{{ record.block_day }}</p>
        </template>
        <template slot="B" slot-scope="text, record">
          <p>{{ record.shift_order }}</p>
          <p>{{ record.postroute }}</p>
          <p>{{ record.departure }}→{{ record.destination }}</p>
        </template>
        <template slot="D" slot-scope="text, record">
          <p>{{ record.vehicle_number }}</p>
          <p>{{ record.vehicle_gnumber }}</p>
        </template>
        <template slot="E" slot-scope="text, record">
          <p>{{ record.vehicle_length || '-' }}</p>
          <p>{{ record.request_weight }}</p>
        </template>
        <template slot="F" slot-scope="text, record">
          <p>{{ record.driver_name }}</p>
          <p>{{ record.driver_mobile }}</p>
        </template>
        <template slot="G" slot-scope="text, record">
          <p>{{ record.block_time || '-' }}</p>
          <p>{{ record.departure_time || '-' }}</p>
          <p>{{ record.arrival_time || '-' }}</p>
        </template>
        <template slot="I" slot-scope="text, record">
          <p>{{ record.serial_no }}</p>
          <p>{{ record.dispatch_no }}</p>
        </template>
        <template slot="J" slot-scope="text, record">
          <p>{{ record.platform }}</p>
          <p>{{ record.platform_no }}</p>
        </template>
        <template slot="paytarget" slot-scope="text, record">
          <v-text-field v-model="record.paytarget" hide-details outlined dense></v-text-field>
          <v-btn tile text small color="primary" @click="savepaytarget(record)"> 保存 </v-btn>
        </template>
        <template slot="real_freight2" slot-scope="text, record">
          <p class="success--text font-weight-bold">{{ record.real_freight2 }}</p>
          <p class="error--text font-weight-bold">{{ record.real_freight2_947 }}</p>
        </template>
        <template slot="delivery_discharge_fee" slot-scope="text, record">
          <p class="success--text font-weight-bold">{{ record.delivery_discharge_fee }}</p>
          <p class="error--text font-weight-bold">{{ record.delivery_discharge_fee_947 }}</p>
        </template>
        <template slot="receipt_deposit" slot-scope="text, record">
          <p class="success--text font-weight-bold">{{ record.receipt_deposit }}</p>
          <p class="error--text font-weight-bold">{{ record.receipt_deposit_947 }}</p>
        </template>
        <template slot="oilcard_day" slot-scope="text, record">
          <p class="success--text font-weight-bold">{{ record.oilcard_day }}</p>
        </template>
        <template slot="fee_request_day" slot-scope="text, record">
          <p class="error--text font-weight-bold">{{ record.fee_request_day }}</p>
        </template>
        <template slot="fee_pay_day" slot-scope="text, record">
          <p class="error--text font-weight-bold">{{ record.fee_pay_day }}</p>
        </template>
        <template slot="receipt_return_day" slot-scope="text, record">
          <p class="primary--text font-weight-bold">{{ record.receipt_return_day }}</p>
        </template>
        <template slot="receipt_request_day" slot-scope="text, record">
          <p class="primary--text font-weight-bold">{{ record.receipt_request_day }}</p>
        </template>
        <template slot="receipt_pay_day" slot-scope="text, record">
          <p class="primary--text font-weight-bold">{{ record.receipt_pay_day }}</p>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
            <!-- <v-btn v-if="!record.paybilllocked" text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
            <span v-else class="error--text text-h6">应付（自营）已锁定</span> -->
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="billModel" persistent max-width="750px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            修改自营账单
            <v-icon @click="cancelupdate">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="error--text text-body-1">
                  <a-row>
                    <a-col :span="8">
                      邮路：{{ billForm.postroute }}
                    </a-col>
                    <a-col :span="8">
                      派车单号：{{ billForm.dispatch_no }}
                    </a-col>
                    <a-col :span="8">
                      流水号：{{ billForm.serial_no }}
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      车牌号：{{ billForm.vehicle_number }}
                    </a-col>
                    <a-col :span="8">
                      挂厢号：{{ billForm.vehicle_gnumber }}
                    </a-col>
                    <a-col :span="8">
                      司机：{{ billForm.driver_name }}/{{ billForm.driver_mobile }}
                    </a-col>
                  </a-row>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="billForm.check_aging" label="考核时效" type="number" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select v-model="billForm.platform" :items="platforms" label="平台" dense outlined
                    :menu-props="{ bottom: true, offsetY: true }" />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-if="billForm.platform === '其他'" v-model="billForm.platform_name" label="平台名称" outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-if="billForm.platform === '其他'" v-model="billForm.platform_rate" label="结算系数"
                    type="number" outlined dense error>
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="billForm.platform === '其他'" class="error--text">
                  平台税点和结算系数转换规则: 例如平台税点5.3%，则系数为 0.947 = 1-0.053
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="billForm.platform_no" label="平台单号" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="billForm.real_freight2" label="平台总价" type="number" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="billForm.check_amount2" label="考核金额" type="number" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="billForm.oilcard_fee" label="油卡金额" type="number" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="oilcard_day" v-model="oilcard_day" :close-on-content-click="false"
                    :return-value.sync="billForm.oilcard_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="billForm.oilcard_day" label="油卡日期" append-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="billForm.oilcard_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
                      @input="() => { $refs.oilcard_day.save(billForm.oilcard_day); oilcard_day = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="billForm.delivery_discharge_fee" label="卸货到付" type="number" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="fee_request_day" v-model="fee_request_day" :close-on-content-click="false"
                    :return-value.sync="billForm.fee_request_day" transition="scale-transition" min-width="auto"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="billForm.fee_request_day" label="到付申请日期" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="billForm.fee_request_day" no-title value="YYYY-mm-dd" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.fee_request_day.save(billForm.fee_request_day); fee_request_day = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="fee_pay_day" v-model="fee_pay_day" :close-on-content-click="false"
                    :return-value.sync="billForm.fee_pay_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="billForm.fee_pay_day" label="到付付款日期" append-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="billForm.fee_pay_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
                      @input="() => { $refs.fee_pay_day.save(billForm.fee_pay_day); fee_pay_day = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="billForm.receipt_deposit" label="回单押金" type="number" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="billForm.gps_no" label="GPS" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="receipt_return_day" v-model="receipt_return_day" :close-on-content-click="false"
                    :return-value.sync="billForm.receipt_return_day" transition="scale-transition" min-width="auto"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="billForm.receipt_return_day" label="GPS回单回收日期" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="billForm.receipt_return_day" no-title value="YYYY-mm-dd" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.receipt_return_day.save(billForm.receipt_return_day); receipt_return_day = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="receipt_request_day" v-model="receipt_request_day" :close-on-content-click="false"
                    :return-value.sync="billForm.receipt_request_day" transition="scale-transition" min-width="auto"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="billForm.receipt_request_day" label="回单申请日期" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="billForm.receipt_request_day" no-title value="YYYY-mm-dd" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.receipt_request_day.save(billForm.receipt_request_day); receipt_request_day = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="receipt_pay_day" v-model="receipt_pay_day" :close-on-content-click="false"
                    :return-value.sync="billForm.receipt_pay_day" transition="scale-transition" min-width="auto"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="billForm.receipt_pay_day" label="回单付款日期" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="billForm.receipt_pay_day" no-title value="YYYY-mm-dd" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.receipt_pay_day.save(billForm.receipt_pay_day); receipt_pay_day = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field v-model="billForm.paytarget" label="收款人" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="billForm.payinfo" label="付款信息" outlined dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="billForm.selfremark" label="备注" rows="2" outlined></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" :disabled="!valid" @click="updatebill">保存</v-btn>
            <v-btn tile @click="cancelupdate">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="selfpaybillmodal" persistent max-width="400px">
      <v-form ref="selfpaybillform" v-model="selfpaybillvalid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            生成应付账款
            <v-icon @click="selfpaybillmodal = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <template v-if="selfpaybillform.waybillids.length > 0">
                  <v-col cols="12">
                    <v-checkbox v-model="selfpaybillform.checked" label="支付运费" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selfpaybillform.amount" label="运费" readonly
                      :disabled="!selfpaybillform.checked" dense outlined hide-details>
                    </v-text-field>
                  </v-col>
                </template>
                <template v-if="selfpaybillform.rwaybillids.length > 0">
                  <v-col cols="12">
                    <v-checkbox v-model="selfpaybillform.rchecked" label="支付押金" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selfpaybillform.ramount" label="押金" readonly
                      :disabled="!selfpaybillform.rchecked" dense outlined hide-details>
                    </v-text-field>
                  </v-col>
                </template>
                <template v-if="selfpaybillform.owaybillids.length > 0">
                  <v-col cols="12">
                    <v-checkbox v-model="selfpaybillform.ochecked" label="支付油卡" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selfpaybillform.oamount" label="油卡" readonly
                      :disabled="!selfpaybillform.ochecked" dense outlined hide-details>
                    </v-text-field>
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-textarea v-model="selfpaybillform.remark" label="备注" rows="3" outlined></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" :disabled="!selfpaybillvalid" @click="generatebill">生成</v-btn>
            <v-btn tile @click="selfpaybillmodal = false">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import UploadTask from "@/components/uploadtask.vue";
export default {
  name: "FinanceSelfBill",
  data() {
    return {
      pages: [
        { text: "20/页", value: 20 },
        { text: "50/页", value: 50 },
        { text: "100/页", value: 100 },
        { text: "200/页", value: 200 },
        { text: '500/页', value: 500 },
        { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      queryForm: {
        nos: "", day_type: "combine_t",
        start_day: this.$moment().format("YYYY-MM-DD"),
        end_day: this.$moment().format("YYYY-MM-DD"),
        province: "", vehicle_number: "", postlineid: "", postlineids: [], self_bill_state: null,
        use_date: false,
      },
      start_day_menu: false,
      end_day_menu: false,
      provinces: [],
      postlines: [],
      self_bill_states: [],

      headers: [
        { dataIndex: "A", slots: { title: "customA" }, scopedSlots: { customRender: "A" }, width: 120, align: "center", },
        { dataIndex: "B", slots: { title: "customB" }, scopedSlots: { customRender: "B" }, width: 160, align: "center", },
        { dataIndex: "D", slots: { title: "customD" }, scopedSlots: { customRender: "D" }, width: 120, align: "center", },
        { dataIndex: "E", slots: { title: "customE" }, scopedSlots: { customRender: "E" }, width: 120, align: "center", },
        { dataIndex: "F", slots: { title: "customF" }, scopedSlots: { customRender: "F" }, width: 150, align: "center", },
        { dataIndex: "G", slots: { title: "customG" }, scopedSlots: { customRender: "G" }, width: 180, align: "center", },
        { dataIndex: "I", slots: { title: "customI" }, scopedSlots: { customRender: "I" }, width: 150, align: "center", },
        { dataIndex: "J", slots: { title: "customJ" }, scopedSlots: { customRender: "J" }, width: 180, align: "center", },
        { title: "收款人", dataIndex: "paytarget", scopedSlots: { customRender: "paytarget" }, width: 150, align: "center", },
        { title: "平台总价", dataIndex: "real_freight2", scopedSlots: { customRender: "real_freight2" }, width: 120, align: "center", },
        { title: "考核金额", dataIndex: "check_amount2", scopedSlots: { customRender: "check_amount2" }, width: 120, align: "center", },
        { title: "油卡金额", dataIndex: "oilcard_fee", scopedSlots: { customRender: "oilcard_fee" }, width: 120, align: "center", },
        { title: "油卡日期", dataIndex: "oilcard_day", scopedSlots: { customRender: "oilcard_day" }, width: 120, align: "center", },
        { title: "卸货到付", dataIndex: "delivery_discharge_fee", scopedSlots: { customRender: "delivery_discharge_fee" }, width: 120, align: "center", },
        { title: "到付申请日期", dataIndex: "fee_request_day", scopedSlots: { customRender: "fee_request_day" }, width: 120, align: "center", },
        { title: "到付付款日期", dataIndex: "fee_pay_day", scopedSlots: { customRender: "fee_pay_day" }, width: 120, align: "center", },
        { title: "回单押金", dataIndex: "receipt_deposit", scopedSlots: { customRender: "receipt_deposit" }, width: 120, align: "center", },
        { title: "GPS", dataIndex: "gps_no", scopedSlots: { customRender: "gps_no" }, width: 120, align: "center", },
        { title: "GPS回单回收日期", dataIndex: "receipt_return_day", scopedSlots: { customRender: "receipt_return_day" }, width: 150, align: "center", },
        { title: "回单申请日期", dataIndex: "receipt_request_day", scopedSlots: { customRender: "receipt_request_day" }, width: 120, align: "center", },
        { title: "回单付款日期", dataIndex: "receipt_pay_day", scopedSlots: { customRender: "receipt_pay_day" }, width: 120, align: "center", },
        { title: "付款信息", dataIndex: "pay_info", scopedSlots: { customRender: "pay_info" }, width: 120, align: "center", },
        { title: "备注", dataIndex: "selfremark", scopedSlots: { customRender: "selfremark" }, width: 120, align: "center", },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: "right", },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      billForm: {},
      billModel: false,
      platforms: [],
      valid: true,
      oilcard_day: false,
      fee_request_day: false,
      fee_pay_day: false,
      receipt_return_day: false,
      receipt_request_day: false,
      receipt_pay_day: false,
      total_freight: null,
      selectedRowKeys: [],
      selfpaybillform: {
        waybillids: [],
        rwaybillids: [],
        owaybillids: [],
      },
      selfpaybillmodal: false,
      selfpaybillvalid: true,
      selfpaybilldatemenu: false,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
    selectAllPostline() {
      return this.queryForm.postlineids.length === this.postlines.length
    },
    selectSomePostline() {
      return this.queryForm.postlineids.length > 0 && !this.selectAllPostline;
    },
    icon() {
      if (this.selectAllPostline) return 'mdi-close-box'
      if (this.selectSomePostline) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  components: { UploadTask },
  watch: {
    '$route.query.nos'(nos) {
      if (nos) {
        this.queryForm.nos = (nos || "").split(',').join('\n');
        this.getdata();
      }
    },
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 380;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 380;
    };
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.queryForm = {
      nos: (this.$route.query.nos || "").split(',').join('\n'),
      day_type: "combine_t",
      start_day: this.$moment().format("YYYY-MM-DD"),
      end_day: this.$moment().format("YYYY-MM-DD"),
      province: "", vehicle_number: "", postlineid: "", postlineids: [], self_bill_state: null,
      use_date: false,
    };
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
          this.provinces = res.data.provinces;
          this.postlines = res.data.postlines;
          this.self_bill_states = res.data.self_bill_states;
          this.total_freight = res.data.total_freight;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/list", { ...this.queryForm, action: 'download' }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download2() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/list", { ...this.queryForm, action: 'download2' }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showedit(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/info", { _id: item._id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.billForm = res.data.billForm;
          this.platforms = res.data.platforms;
          this.billModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    updatebill() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/update", { billForm: this.billForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.billModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelupdate() {
      this.$refs.form.resetValidation();
      this.billModel = false;
    },
    savepaytarget(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/savepaytarget", item).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllPostline) {
          this.queryForm.postlineids = []
        } else {
          this.queryForm.postlineids = _.pluck(this.postlines, "value");
        }
      })
    },
    changeSelectedRows(keys) {
      let list = _.filter(this.list, n => keys.indexOf(n._id) > -1);
      this.selectedRowKeys = _.pluck(list, "_id");
      console.log(this.selectedRowKeys);
    },
    ///生成自营应付账款（通过第三方平台支付）
    makepayable() {
      if (this.selectedRowKeys.length === 0) return this.$toast.fail("未选择账单");
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/calcpaybill", { waybillids: this.selectedRowKeys }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.selfpaybillform = res.data.selfpaybillform;
          this.selfpaybillmodal = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    generatebill() {
      if (!this.$refs.selfpaybillform.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbill/generatepaybill", { ...this.selfpaybillform, v: "2" }).then((res) => {
        if (res.code === 0) {
          this.$toast.success("已生成");
          this.selfpaybillmodal = false;
        } else {
          this.$toast.clear();
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}

p {
  margin: 0;
}
</style>
