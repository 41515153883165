<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <v-text-field v-model="queryForm.vehicle_number" label="车牌号" outlined dense clearable hide-details />
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <v-btn color="cyan" tile style="color:#fff;" @click="getdata">
          <v-icon small>mdi-magnify</v-icon> 查询
        </v-btn>
      </v-col>
    </v-row>
    <v-card tile class="mt-2">
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex align-center pl-2">
            <span>共 {{ length }} 页 / {{ count }} 条数据</span>
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                  {{ limit }}/页
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                  <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="flush_time" slot-scope="text, record">
          {{ record.flush_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="minutes" slot-scope="text, record">
          {{ record.minutes }}分钟前更新
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a-popconfirm title="停止更新" ok-text="确定" cancel-text="取消" @confirm="stop(record._id)">
              <v-btn text tile color="error"> 停止更新 </v-btn>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "VehicleMonit",
  data() {
    return {
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        { text: '200/页', value: 200 },
        // { text: '500/页', value: 500 },
        // { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      queryForm: { vehicle_number: "", },
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 80, align: "center" },
        { title: "车牌号", dataIndex: "vehicle_number", scopedSlots: { customRender: "vehicle_number" }, width: 120, align: "center" },
        { title: "更新时间", dataIndex: "flush_time", scopedSlots: { customRender: "flush_time" }, width: 180, align: "center" },
        { title: "位置更新", dataIndex: "minutes", scopedSlots: { customRender: "minutes" }, width: 180, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      page: 1,
      limit: 200,
      list: [],
      length: 0,
      count: 0,
      height: 600,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehiclemonit/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    stop(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehiclemonit/stop", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
