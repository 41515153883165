<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-7" cols="12" lg="3" md="3" sm="6">
        <v-card tile>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="info" fab rounded dark>
                <v-icon>mdi-wallet</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">{{ day_departure_count }}</h2>
                <h5 class="subtitle-2 font-weight-regular">昨日发车</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-7" cols="12" lg="3" md="3" sm="6">
        <v-card tile>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="warning" fab rounded dark>
                <v-icon>mdi-cart-outline</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">{{ month_departure_count }}</h2>
                <h5 class="subtitle-2 font-weight-regular">本月发车</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-7" cols="12" lg="3" md="3" sm="6">
        <v-card tile>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="deep-purple" fab rounded dark>
                <v-icon>mdi-currency-usd</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">￥{{ day_real_freight }}</h2>
                <h5 class="subtitle-2 font-weight-regular">昨日运费</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-7" cols="12" lg="3" md="3" sm="6">
        <v-card tile>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="error" fab rounded dark>
                <v-icon>mdi-cash-multiple</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">￥{{ month_real_freight }}</h2>
                <h5 class="subtitle-2 font-weight-regular">本月运费</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-menu ref="date_menu" v-model="date_menu" :close-on-content-click="false" :return-value.sync="date"
      transition="scale-transition" min-width="auto" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="date" label="日期" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense
          outlined>
        </v-text-field>
      </template>
      <v-date-picker v-model="date" no-title scrollable locale="zh-cn"
        @input="() => { $refs.date_menu.save(date); date_menu = false; getdata() }"></v-date-picker>
    </v-menu>
    <div v-for="project in projects" :key="project.value" class="project">
      <v-row>
        <v-col cols="12" sm="12">
          <v-card tile>
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="4">
                <div class="pa-5">
                  <h3 class="text-truncate font-weight-regular">{{ project.label }}项目</h3>
                  <h6 class="body-2 mb-0 blue-grey--text text--lighten-2 font-weight-light" v-if="desc">数据来源于{{ desc }}
                  </h6>
                </div>
              </v-col>
              <v-col cols="12" lg="8" md="8" sm="8">
                <v-row>
                  <v-col cols="12" lg="3" md="6">
                    <div class="d-flex align-items-center pa-5">
                      <div>
                        <h3 class="font-weight-regular">{{ projectdata[project.value].day_departure_count }}</h3>
                        <h5 class="blue-grey--text text--lighten-2 font-weight-regular subtitle-1">昨日发车
                        </h5>
                      </div>
                      <div class="spacer"></div>
                      <div class="ml-auto">
                        <v-btn class="elevation-0" color="info" fab rounded dark>
                          <v-icon>mdi-wallet</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3" md="6">
                    <div class="d-flex align-items-center pa-5">
                      <div>
                        <h3 class="font-weight-regular">{{ projectdata[project.value].month_departure_count }}</h3>
                        <h5 class="blue-grey--text text--lighten-2 font-weight-regular subtitle-1">本月发车
                        </h5>
                      </div>
                      <div class="spacer"></div>
                      <div class="ml-auto">
                        <v-btn class="elevation-0" color="warning" fab rounded dark>
                          <v-icon>mdi-cart-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3" md="6">
                    <div class="d-flex align-items-center pa-5">
                      <div>
                        <h3 class="font-weight-regular">{{ projectdata[project.value].day_real_freight }}</h3>
                        <h5 class="blue-grey--text text--lighten-2 font-weight-regular subtitle-1">昨日运费
                        </h5>
                      </div>
                      <div class="spacer"></div>
                      <div class="ml-auto">
                        <v-btn class="elevation-0" color="deep-purple" fab rounded dark>
                          <v-icon>mdi-currency-usd</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3" md="6">
                    <div class="d-flex align-items-center pa-5">
                      <div>
                        <h3 class="font-weight-regular">{{ projectdata[project.value].month_real_freight }}</h3>
                        <h5 class="blue-grey--text text--lighten-2 font-weight-regular subtitle-1">本月运费
                        </h5>
                      </div>
                      <div class="spacer"></div>
                      <div class="ml-auto">
                        <v-btn class="elevation-0" color="error" fab rounded dark>
                          <v-icon>mdi-cash-multiple</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" lg="5">
                <h4 class="t-title">发车情况</h4>
                <v-data-table :headers="headers" :items="projectdata[project.value].list1"
                  :items-per-page="projectdata[project.value].list1.length" class="elevation-1" no-data-text="无数据"
                  hide-default-footer disable-sort>
                  <template v-slot:item.day_departure_count="{ item }">
                    <v-btn v-if="item.day_departure_count > 0" text color="primary"
                      @click="showdetail1(item, project.value)">
                      {{ item.day_departure_count }}
                    </v-btn>
                    <span v-else>{{ item.day_departure_count }}</span>
                  </template>
                  <template v-slot:item.month_departure_count="{ item }">
                    <v-btn v-if="item.month_departure_count > 0" text color="primary"
                      @click="showdetail2(item, project.value)">
                      {{ item.month_departure_count }}
                    </v-btn>
                    <span v-else>{{ item.month_departure_count }}</span>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" sm="7" lg="7">
                <h4 class="t-title">运行情况</h4>
                <v-data-table :headers="headers2" :items="projectdata[project.value].list2"
                  :items-per-page="projectdata[project.value].list2.length" class="elevation-1" no-data-text="无数据"
                  hide-default-footer disable-sort>
                  <template v-slot:item.delay_count="{ item }">
                    <v-btn v-if="item.delay_count > 0" text color="primary" @click="showdetail3(item, project.value)">
                      {{ item.delay_count }}
                    </v-btn>
                    <span v-else>{{ item.delay_count }}</span>
                  </template>
                  <template v-slot:item.nogps_count="{ item }">
                    <v-btn v-if="item.nogps_count > 0" text color="primary" @click="showdetail4(item, project.value)">
                      {{ item.nogps_count }}
                    </v-btn>
                    <span v-else>{{ item.nogps_count }}</span>
                  </template>
                </v-data-table>
                <template v-if="project.self">
                  <h4 class="t-title" style="margin-top:10px;">自营数据</h4>
                  <v-data-table :headers="headers3" :items="projectdata[project.value].list3"
                    :items-per-page="projectdata[project.value].list3.length" class="elevation-1" no-data-text="无数据"
                    hide-default-footer disable-sort>
                    <template v-slot:item._freight1="{ item }">
                      <span :title="item.freight1">{{ item._freight1 }}</span>
                    </template>
                    <template v-slot:item._freight2="{ item }">
                      <span :title="item.freight2">{{ item._freight2 }}</span>
                    </template>
                    <template v-slot:item._profit="{ item }">
                      <span :title="item.profit">{{ item._profit }}</span>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="detail_model" persistent max-width="960px">
      <v-card tile>
        <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
          发车详情
          <v-icon @click="() => detail_model = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <a-table size="small" :columns="detail_header" bordered :data-source="detail_list" :pagination="false"
            :locale="{ emptyText: '暂无数据' }">
            <template slot="line" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.line }}</span>
            </template>
            <template slot="weight_12" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_12 }}</span>
            </template>
            <template slot="weight_15" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_15 }}</span>
            </template>
            <template slot="weight_20" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_20 }}</span>
            </template>
            <template slot="weight_25" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_25 }}</span>
            </template>
            <template slot="weight_30" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_30 }}</span>
            </template>
            <template slot="weight_35" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_35 }}</span>
            </template>
            <template slot="weight_40" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_40 }}</span>
            </template>
            <template slot="weight_undefined" slot-scope="text, item">
              <span :class="{ 'text-h6 error--text': item.line === '合计' }">{{ item.weight_undefined }}</span>
            </template>
            <template slot="total" slot-scope="text, item">
              <span class="text-h6 error--text">{{ item.total }}</span>
            </template>
          </a-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import Vue from "vue";
import { Empty } from "vant";
Vue.use(Empty);
export default {
  name: "Daily",
  data() {
    return {
      date_menu: false,
      date: '',
      desc: '',
      day_departure_count: 0,
      month_departure_count: 0,
      day_real_freight: "0.00",
      month_real_freight: "0.00",
      headers: [
        { text: "邮局", value: "dispatch_post_office", align: "center", width: 100 },
        { text: "昨日", value: "day_departure_count", align: "center", width: 150 },
        { text: "本月", value: "month_departure_count", align: "center", width: 150 },
      ],
      headers2: [
        { text: "#", value: "title", align: "center", width: 80 },
        { text: "总到达", value: "arrival_count", align: "center", width: 100 },
        { text: "无进(出)围栏数", value: "nogps_count", align: "center", width: 120 },
        { text: "GPS采集率", value: "gps_rate", align: "center", width: 120 },
        { text: "晚点", value: "delay_count", align: "center", width: 80 },
        { text: "准点率", value: "right_rate", align: "center", width: 100 },
      ],
      headers3: [
        { text: "#", value: "title", align: "center", width: 80 },
        { text: "发车", value: "departure_count", align: "center", width: 100 },
        { text: "到达", value: "arrival_count", align: "center", width: 100 },
        { text: "预估收入", value: "_freight1", align: "center", width: 150 },
        { text: "成本", value: "_freight2", align: "center", width: 150 },
        { text: "利润", value: "_profit", align: "center", width: 120 },
        { text: "利润率", value: "rate", align: "center", width: 100 },
      ],
      projects: [
        // { label: "河南(一干)", value: "henan1", self: true, },
        // { label: "河南(一干)", value: "henan1_ld", self: true, },
        // { label: "河南(中邮)", value: "henan1_zy", self: true, },
        { label: "河南(二干)", value: "henan2", self: false, },
        // { label: "广东", value: "guangdong", self: true, },
        // { label: "江西", value: "jiangxi", self: true, },
        // { label: "浙江", value: "zhejiang", self: false, },
        // { label: "广西", value: "guangxi", self: false, },
      ],
      henan: null,
      henan1: null,
      henan1_ld: null,
      henan1_zy: null,
      henan2: null,
      guangdong: null,
      jiangxi: null,
      zhejiang: null,
      guangxi: null,
      detail_header: [
        { title: "线路名称", dataIndex: "line", scopedSlots: { customRender: "line" }, align: "center", width: 120 },
        { title: "12吨", dataIndex: "weight_12", scopedSlots: { customRender: "weight_12" }, align: "center", width: 80 },
        { title: "15吨", dataIndex: "weight_15", scopedSlots: { customRender: "weight_15" }, align: "center", width: 80 },
        { title: "20吨", dataIndex: "weight_20", scopedSlots: { customRender: "weight_20" }, align: "center", width: 80 },
        { title: "25吨", dataIndex: "weight_25", scopedSlots: { customRender: "weight_25" }, align: "center", width: 80 },
        { title: "30吨", dataIndex: "weight_30", scopedSlots: { customRender: "weight_30" }, align: "center", width: 80 },
        { title: "35吨", dataIndex: "weight_35", scopedSlots: { customRender: "weight_35" }, align: "center", width: 80 },
        { title: "40吨", dataIndex: "weight_40", scopedSlots: { customRender: "weight_40" }, align: "center", width: 80 },
        { title: "未匹配", dataIndex: "weight_undefined", scopedSlots: { customRender: "weight_undefined" }, align: "center", width: 80 },
        { title: "合计", dataIndex: "total", scopedSlots: { customRender: "total" }, align: "center", width: 80 },
      ],
      detail_list: [],
      detail_model: false,
    };
  },
  computed: {
    projectdata() {
      return {
        henan: this.henan || this.initdata(),
        henan1: this.henan1 || this.initdata(),
        henan1_ld: this.henan1_ld || this.initdata(),
        henan1_zy: this.henan1_zy || this.initdata(),
        henan2: this.henan2 || this.initdata(),
        guangdong: this.guangdong || this.initdata(),
        jiangxi: this.jiangxi || this.initdata(),
        zhejiang: this.zhejiang || this.initdata(),
        guangxi: this.guangxi || this.initdata(),
      }
    },
  },
  watch: {},
  mounted() {
    console.log("daily-mounted");
  },
  activated() {
    this.date = this.$moment().format("YYYY-MM-DD");
    this.getdata();
  },
  methods: {
    initdata() {
      return {
        day_departure_count: 0,
        month_departure_count: 0,
        day_real_freight: 0,
        month_real_freight: 0,
        list1: [],
        list2: [],
        list3: [],
      };
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/statistics/dailydata", { date: this.date }).then((res) => {
        // console.log(res);
        this.desc = res.data.desc;
        this.day_departure_count = res.data.day_departure_count;
        this.month_departure_count = res.data.month_departure_count;
        this.day_real_freight = res.data.day_real_freight;
        this.month_real_freight = res.data.month_real_freight;
        this.henan = res.data.henan;
        this.henan1 = res.data.henan1;
        this.henan1_ld = res.data.henan1_ld;
        this.henan1_zy = res.data.henan1_zy;
        this.henan2 = res.data.henan2;
        this.guangdong = res.data.guangdong;
        this.jiangxi = res.data.jiangxi;
        this.zhejiang = res.data.zhejiang;
        this.guangxi = res.data.guangxi;
        this.$toast.clear();
      });
    },
    makedetaillist(list) {
      let _total = 0, weight_12 = '', weight_15 = '', weight_20 = '', weight_25 = '', weight_30 = '', weight_35 = '', weight_40 = '', weight_undefined = '';
      let detail_list = _.map(list, n => {
        let total = _.reduce(_.values(n.data), (a, b) => a + b, 0);
        _total += total;
        if (n.data['12']) {
          if (weight_12 === '') weight_12 = 0;
          weight_12 += n.data['12'];
        }
        if (n.data['15']) {
          if (weight_15 === '') weight_15 = 0;
          weight_15 += n.data['15'];
        }
        if (n.data['20']) {
          if (weight_20 === '') weight_20 = 0;
          weight_20 += n.data['20'];
        }
        if (n.data['25']) {
          if (weight_25 === '') weight_25 = 0;
          weight_25 += n.data['25'];
        }
        if (n.data['30']) {
          if (weight_30 === '') weight_30 = 0;
          weight_30 += n.data['30'];
        }
        if (n.data['35']) {
          if (weight_35 === '') weight_35 = 0;
          weight_35 += n.data['35'];
        }
        if (n.data['40']) {
          if (weight_40 === '') weight_40 = 0;
          weight_40 += n.data['40'];
        }
        if (n.data['undefined']) {
          if (weight_undefined === '') weight_undefined = 0;
          weight_undefined += n.data['undefined'];
        }
        return {
          ...n,
          weight_12: n.data['12'] || '',
          weight_15: n.data['15'] || '',
          weight_20: n.data['20'] || '',
          weight_25: n.data['25'] || '',
          weight_30: n.data['30'] || '',
          weight_35: n.data['35'] || '',
          weight_40: n.data['40'] || '',
          weight_undefined: n.data['undefined'] || '',
          total,
        };
      });
      this.detail_list = [
        ...detail_list,
        {
          line: "合计",
          weight_12,
          weight_15,
          weight_20,
          weight_25,
          weight_30,
          weight_35,
          weight_40,
          weight_undefined,
          total: _total,
        }
      ];
    },
    showdetail1(item, province) {
      console.log('showdetail1', item, province);
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/statistics/showdetail1", { dispatch_post_office: item.dispatch_post_office, province }).then((res) => {
        console.log(res);
        this.$toast.clear();
        if (res.code === 0) {
          this.makedetaillist(res.data.data);
          this.detail_model = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
      });
    },
    showdetail2(item, province) {
      console.log('showdetail2', item, province);
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/statistics/showdetail2", { dispatch_post_office: item.dispatch_post_office, province }).then((res) => {
        console.log(res);
        this.$toast.clear();
        if (res.code === 0) {
          this.makedetaillist(res.data.data);
          this.detail_model = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
      });
    },
    showdetail3(item, province) {
      console.log('showdetail3', item, province);
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/statistics/showdetail3", { title: item.title, province }).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$api.doAPI("/waybill/list", { temp_download_id: res.data._id, action: 'download' }).then((res) => {
            this.$toast.clear();
            // console.log(res);
            if (res.code === 0) {
              this.$download(res.data.url, res.data.name);
            } else {
              this.$alert(res.msg);
            }
          }).catch((err) => {
            this.$toast.clear();
            console.error(err);
          });
        } else {
          this.$toast.clear();
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
      });
    },
    showdetail4(item, province) {
      console.log('showdetail4', item, province);
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/statistics/showdetail4", { title: item.title, province }).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$api.doAPI("/waybill/list", { temp_download_id: res.data._id, action: 'download' }).then((res) => {
            this.$toast.clear();
            // console.log(res);
            if (res.code === 0) {
              this.$download(res.data.url, res.data.name);
            } else {
              this.$alert(res.msg);
            }
          }).catch((err) => {
            this.$toast.clear();
            console.error(err);
          });
        } else {
          this.$toast.clear();
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.v-application .mt-9 {
  margin-top: 18px !important;
}

.t-title {
  padding: 0 20px;
}

.project {
  margin-bottom: 20px;
}

/deep/ .ant-table-thead>tr>th {
  padding: 8px 8px !important;
}

/deep/ .ant-table-tbody>tr>td {
  padding: 8px 8px !important;
}
</style>
