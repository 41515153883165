<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <a-range-picker v-model="queryForm.months" :mode="mode" :placeholder="['开始月份', '结束月份']" :locale="locale"
          :allowClear="false" format="YYYY-MM" valueFormat="YYYY-MM" size="large" style="width:100%;"
          @change="changeMonth" @panelChange="changeMonthPanel" />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.province" :items="provinces" label="省份"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.postofficeid" :items="postoffices" label="邮局"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.corp" :items="corps" label="所属公司"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <v-text-field v-model="queryForm.dispatch_response_corp" label="派车响应单位" dense outlined clearable hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="3" sm="6">
        <a-space>
          <v-btn @click="getdata()" color="cyan" dark tile>
            <v-icon small>mdi-magnify</v-icon>查询
          </v-btn>
          <v-btn @click="getdata('download')" color="indigo" dark tile>
            <v-icon small>mdi-download</v-icon>下载应收报表
          </v-btn>
        </a-space>
      </v-col>
    </v-row>
    <a-tabs v-model="queryForm.stag" @change="getdata()">
      <a-tab-pane key="1" tab="待审核"></a-tab-pane>
      <a-tab-pane key="2" tab="已审核"></a-tab-pane>
      <a-tab-pane key="3" tab="未核销"></a-tab-pane>
      <a-tab-pane key="4" tab="已核销"></a-tab-pane>
      <a-tab-pane key="all" tab="全部"></a-tab-pane>
    </a-tabs>
    <div class="tips" style="margin-bottom: 10px;">
      <a-alert message="应收审批流程：结算审核 》出纳核销" type="info" show-icon />
    </div>
    <v-card>
      <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false" bordered
        :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }">
        <template slot="province" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.province }}</span>
        </template>
        <template slot="corp" slot-scope="text, record">
          <p>{{ record.corp }}</p>
          <p v-if="record.tax" class="error--text">费率：{{ record.tax }}%</p>
        </template>
        <template slot="money" slot-scope="text, record">
          <p :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.money }}</p>
          <v-btn v-if="record.postofficeid" small tile text color="primary" @click="showbilllist(record)">下载明细</v-btn>
        </template>
        <template slot="verify_money" slot-scope="text, record">
          <p :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.verify_money }}</p>
          <v-btn v-if="record.postofficeid" small tile text color="primary" @click="showverifylist(record)">查看明细</v-btn>
        </template>
        <template slot="verify_time" slot-scope="text, record, index">
          <p :class="{ 'error--text': record.verify_time && record.verify_time !== '-' && index < 4 }">
            {{ record.verify_time }}
          </p>
        </template>
        <template slot="left_money" slot-scope="text, record">
          <p :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.left_money }}</p>
        </template>
        <template slot="state" slot-scope="text, record">
          <p v-if="record.review_state === 'waiting'" class="orange--text">结算待审核</p>
          <p v-if="record.verify_state === 'waiting'" class="error--text">出纳待核销</p>
          <p v-if="record.verify_state === 'finished'" class="success--text">出纳已核销</p>
        </template>
        <template slot="remark" slot-scope="text, record">
          <p>{{ record.remark }}</p>
          <v-btn v-if="record.province !== '合计'" text tile small color="primary" @click="editRemark(record)">
            编辑
          </v-btn>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space v-if="record.province !== '合计'">
            <v-btn v-if="username === 'root'" small tile text color="error" @click="calcbill(record)"> 计算 </v-btn>
            <v-btn
              v-if="record.corp === '宏达' && record.review_state === 'finished' && (role === 'root' || role === 'admin' || role === 'jiesuan')"
              small tile text color="error" @click="editTax(record)"> 设置费率 </v-btn>
          </a-space>
          <a-space>
            <v-btn
              v-if="record.review_state === 'waiting' && (role === 'root' || role === 'admin' || role === 'jiesuan')"
              small tile text color="orange" @click="showReview(record)">
              审核
            </v-btn>
            <a-popconfirm v-if="record.verify_state === 'waiting' && username === 'root'" title="解锁账单" ok-text="确定"
              cancel-text="取消" @confirm="rollbackReview(record)">
              <v-btn small tile text color="error"> 解锁账单 </v-btn>
            </a-popconfirm>
          </a-space>
          <a-space v-if="record.verify_state === 'waiting' && (role === 'root' || role === 'chuna')">
            <v-btn small tile text color="error" @click="showVerify(record)">
              核销
            </v-btn>
            <a-popconfirm v-if="record.verify_finish" title="核销完成" ok-text="确定" cancel-text="取消"
              @confirm="finishVerify(record)">
              <v-btn small tile text color="error"> 核销完成 </v-btn>
            </a-popconfirm>
          </a-space>
          <a-popconfirm v-if="username === 'root' && record.verify_state === 'finished'" title="重新核销" ok-text="确定"
            cancel-text="取消" @confirm="reVerify(record)">
            <v-btn small tile text color="error"> 重新核销 </v-btn>
          </a-popconfirm>
          <v-btn v-if="record.province !== '合计'" small tile text color="#800080"
            @click="showinvoice(record)">发票账单</v-btn>
        </template>
      </a-table>
    </v-card>
    <v-dialog v-model="verifymodel" persistent max-width="900px">
      <v-card>
        <v-card-title class="primary dark d-flex justify-space-between">
          <span style="color: #fff;">应收账款核销明细</span>
          <v-icon @click="verifymodel = false" class="white--text">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <a-table :columns="verifyheaders" :data-source="verifylist" bordered row-key="_id"
            :pagination="{ hideOnSinglePage: true }" :locale="{ emptyText: '暂无数据' }" size="small">
            <template slot="index" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
            <template slot="action" slot-scope="text, record">
              <a-popconfirm v-if="record.removeable && (role === 'root' || role === 'chuna')" title="删除" ok-text="确定"
                cancel-text="取消" @confirm="removeVerify(record._id)">
                <v-btn tile text color="error"> 删除 </v-btn>
              </a-popconfirm>
            </template>
          </a-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--1、会计或结算审核-->
    <v-dialog v-model="reviewModel" max-width="500px">
      <v-form ref="reviewForm" v-model="reviewValid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            审核应收账款
            <v-icon @click="cancelReview">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="reviewForm.province" label="项目" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="reviewForm.postoffice" label="邮局" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="reviewForm.month" label="月份" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="reviewForm.amount" label="应收金额" hide-details disabled
                    class="error--text text-h6">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="reviewForm.remark" label="备注" dense></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="saveReview" :disabled="!reviewValid">审核通过</v-btn>
            <v-btn tile @click="cancelReview">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!--2、出纳核销-->
    <v-dialog v-model="verifyModel" persistent max-width="500px">
      <v-form ref="verifyForm" v-model="verifyValid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            核销应收账款
            <v-icon @click="cancelVerify">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.province" label="项目" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.postoffice" label="邮局" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.month" label="对账月份" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.receive_day" label="应收日期" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.corp" label="所属公司" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.dispatch_response_corp" label="派车响应单位" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.amount" label="应收金额" hide-details disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.verify_amount" label="已核销金额" disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="verifyForm.left_amount" type="number" :rules="[vrules.required]"
                    append-icon="mdi-currency-cny" outlined dense>
                    <template v-slot:label>
                      核销金额<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <p>回款时间：</p>
                  <a-date-picker v-model="verifyForm.verify_time" :locale="locale" :show-time="{ format: 'HH:mm:ss' }"
                    placeholder="回款时间" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" size="large"
                    :allowClear="false" style="width:100%" />
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="verifyForm.remark" label="备注" rows="2" outlined dense></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="saveVerify" :disabled="!verifyValid">提交</v-btn>
            <v-btn tile @click="cancelVerify">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="remarkModel" persistent max-width="500px">
      <v-card>
        <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
          修改备注
          <v-icon @click="remarkModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="remarkForm.remark" label="备注" rows="3" outlined dense></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" @click="saveRemark">提交</v-btn>
          <v-btn tile @click="remarkModel = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="taxModel" persistent max-width="400px">
      <v-card>
        <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
          设置费率
          <v-icon @click="taxModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="taxForm.province" label="项目" hide-details disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="taxForm.postoffice" label="邮局" hide-details disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="taxForm.month" label="对账月份" hide-details disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="taxForm.receive_day" label="应收日期" hide-details disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="taxForm.corp" label="所属公司" hide-details disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="taxForm.dispatch_response_corp" label="派车响应单位" hide-details disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="taxForm.tax" type="number" label="费率" suffix="%" outlined dense
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" @click="saveTax">提交</v-btn>
          <v-btn tile @click="taxModel = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invoiceModel" persistent max-width="750px">
      <v-card>
        <v-card-title class="grey lighten-4 text-subtitle-1 font-weight-bold d-flex justify-space-between">
          发票及账单
          <v-icon @click="invoiceModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-2 mt-2">
            <v-col cols="12" class="d-flex">
              <v-file-input v-model="invoiceForm.file" label="发票账单" placeholder="发票账单" persistent-placeholder
                prepend-icon="mdi-file" show-size outlined hide-details dense>
                <template v-slot:selection="{ text }">
                  <v-chip label small color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
              <v-btn class="ml-2" color="primary" text tile :disabled="!invoiceForm.file" @click="doupload"> 上传
              </v-btn>
            </v-col>
          </v-row>
          <a-table :columns="invoiceHeaders" bordered :data-source="invoiceList" :pagination="false"
            :locale="{ emptyText: '暂无数据' }" :scroll="{ y: 400 }" size="small">
            <template slot="originalname" slot-scope="text, record">
              <a href="javascript:;" @click="downloadfile(record)">{{ record.originalname }}</a>
            </template>
            <template slot="create_time" slot-scope="text, record">
              {{ record.create_time | dateTime("YYYY-MM-DD HH:mm") }}
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-popconfirm v-if="record.removeable" title="删除发票及账单" ok-text="确定" cancel-text="取消"
                @confirm="removeinvoice(record)">
                <v-btn color="error" text tile>删除</v-btn>
              </a-popconfirm>
            </template>
          </a-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

export default {
  name: "FinanceReceiveable",
  data() {
    return {
      locale,
      mode: ['month', 'month'],
      queryForm: {
        months: [this.$utils.moment().format('YYYY-MM'), this.$utils.moment().format('YYYY-MM')],
        province: "",
        postofficeid: "",
        corp: "",
        dispatch_response_corp: "",
        stag: '1',
      },
      provinces: [],
      postoffices: [],
      corps: [],
      dispatch_response_corp: "",
      headers: [
        { title: "项目", dataIndex: "province", scopedSlots: { customRender: "province" }, width: 100, align: "center" },
        { title: "邮局", dataIndex: "postoffice", scopedSlots: { customRender: "postoffice" }, width: 120, align: "center" },
        { title: "所属公司", dataIndex: "corp", scopedSlots: { customRender: "corp" }, width: 120, align: "center" },
        { title: "派车响应单位", dataIndex: "dispatch_response_corp", scopedSlots: { customRender: "dispatch_response_corp" }, width: 120, align: "center" },
        { title: "对账月份", dataIndex: "month", scopedSlots: { customRender: "month" }, width: 120, align: "center" },
        { title: "应收日期", dataIndex: "receive_day", scopedSlots: { customRender: "receive_day" }, width: 120, align: "center" },
        { title: "应收金额", dataIndex: "money", scopedSlots: { customRender: "money" }, width: 150, align: "center" },
        { title: "核销金额", dataIndex: "verify_money", scopedSlots: { customRender: "verify_money" }, width: 150, align: "center" },
        { title: "回款时间", dataIndex: "verify_time", scopedSlots: { customRender: "verify_time" }, width: 120, align: "center" },
        { title: "剩余金额", dataIndex: "left_money", scopedSlots: { customRender: "left_money" }, width: 150, align: "center" },
        { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 100, align: "center" },//待审核、已通过、待核销、已核销
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 150, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      list: [],
      height: 600,
      verifymodel: false,
      verifyquery: {},
      verifylist: [],
      verifyheaders: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 80, align: "center" },
        { title: "金额", dataIndex: "amount", scopedSlots: { customRender: "amount" }, width: 120, align: "center" },
        { title: "回款时间", dataIndex: "time", scopedSlots: { customRender: "time" }, width: 120, align: "center" },
        { title: "操作人", dataIndex: "opuser", scopedSlots: { customRender: "opuser" }, width: 120, align: "center" },
        { title: "操作时间", dataIndex: "optime", scopedSlots: { customRender: "optime" }, width: 120, align: "center" },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 150, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 120, align: "center" },
      ],
      reviewModel: false,
      reviewForm: {},
      reviewValid: true,
      invoiceModel: false,
      invoiceForm: {},
      invoiceList: [],
      invoiceHeaders: [
        { title: "文件名", dataIndex: "originalname", scopedSlots: { customRender: "originalname" }, width: 300, },
        { title: "上传人", dataIndex: "create_by", scopedSlots: { customRender: "create_by" }, width: 100, },
        { title: "上传时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 160, },
        { title: "操作", dataIndex: "operation", scopedSlots: { customRender: "operation" }, width: 120, },
      ],
      verifyModel: false,
      verifyForm: {},
      verifyValid: true,
      verifyMenu: false,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      remarkModel: false,
      remarkForm: {},
      taxForm: {},
      taxModel: false,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {},
  mounted() {
    this.height = window.innerHeight - 300;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 300;
    };
    this.getdata();
  },
  activated() {
  },
  methods: {
    changeMonth(value) {
      this.queryForm.months = [value[0].format('YYYY-MM'), value[1].format('YYYY-MM')];
    },
    changeMonthPanel(value, mode) {
      this.queryForm.months = [value[0].format('YYYY-MM'), value[1].format('YYYY-MM')];
      this.mode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
    },
    getdata(action) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/list", { ...this.queryForm, action }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          if (action === 'download') return this.$download(res.data.url, res.data.name);
          let list = _.map(res.data.list, n => {
            return {
              ...n,
              money: this.$utils.formatMoney(n.amount, 2, ''),
              verify_money: this.$utils.formatMoney(n.verify_amount, 2, ''),
              left_money: this.$utils.formatMoney(n.left_amount, 2, ''),
            }
          });
          list.unshift(list[list.length - 1]);
          list.splice(list.length - 1)
          this.list = list;
          this.provinces = res.data.provinces;
          this.postoffices = res.data.postoffices;
          this.corps = res.data.corps;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showbilllist(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/billlist", item).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showverifylist(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/verifylist", item).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.verifyquery = item;
          this.verifylist = res.data.list;
          this.verifymodel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    calcbill(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/calc", item).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$alert(`应收金额：${res.data.amount}`)
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showReview(item) {
      this.reviewForm = {
        province: item.province,
        postofficeid: item.postofficeid,
        postoffice: item.postoffice,
        month: item.month,
        receive_day: item.receive_day,
        corp: item.corp,
        dispatch_response_corp: item.dispatch_response_corp,
        amount: item.amount,
        remark: "",
      };
      this.reviewModel = true;
    },
    saveReview() {
      if (!this.$refs.reviewForm.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/savereview", { reviewForm: this.reviewForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.reviewModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelReview() {
      this.$refs.reviewForm.resetValidation();
      this.reviewModel = false;
    },
    rollbackReview(item) {
      this.reviewForm = {
        province: item.province,
        postofficeid: item.postofficeid,
        postoffice: item.postoffice,
        month: item.month,
        receive_day: item.receive_day,
        corp: item.corp,
        dispatch_response_corp: item.dispatch_response_corp,
      };
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/rollbackreview", { reviewForm: this.reviewForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showVerify(item) {
      this.verifyForm = {
        province: item.province,
        postofficeid: item.postofficeid,
        postoffice: item.postoffice,
        month: item.month,
        receive_day: item.receive_day,
        corp: item.corp,
        dispatch_response_corp: item.dispatch_response_corp,
        amount: item.amount,
        verify_amount: item.verify_amount,
        left_amount: item.left_amount,
        verify_time: '',//this.$utils.dateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss'),
        remark: "",
      };
      this.verifyModel = true;
    },
    saveVerify() {
      if (!this.$refs.verifyForm.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/saveverify", { verifyForm: this.verifyForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.verifyModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelVerify() {
      this.$refs.verifyForm.resetValidation();
      this.verifyModel = false;
    },
    removeVerify(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/removeverify", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.showverifylist(this.verifyquery);
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    finishVerify(item) {
      let data = {
        province: item.province,
        postofficeid: item.postofficeid,
        month: item.month,
        receive_day: item.receive_day,
        corp: item.corp,
        dispatch_response_corp: item.dispatch_response_corp,
      };
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/finishverify", data).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    reVerify(item) {
      let data = {
        province: item.province,
        postofficeid: item.postofficeid,
        month: item.month,
        receive_day: item.receive_day,
        corp: item.corp,
        dispatch_response_corp: item.dispatch_response_corp,
      };
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/reverify", data).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    editRemark(item) {
      this.remarkForm = {
        province: item.province,
        postofficeid: item.postofficeid,
        month: item.month,
        receive_day: item.receive_day,
        corp: item.corp,
        dispatch_response_corp: item.dispatch_response_corp,
        remark: item.remark,
      };
      this.remarkModel = true;
    },
    saveRemark() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/saveremark", { remarkForm: this.remarkForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.remarkModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    editTax(item) {
      this.taxForm = {
        province: item.province,
        postoffice: item.postoffice,
        postofficeid: item.postofficeid,
        month: item.month,
        receive_day: item.receive_day,
        corp: item.corp,
        dispatch_response_corp: item.dispatch_response_corp,
        tax: item.tax,
      };
      this.taxModel = true;
    },
    saveTax() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/savetax", { taxForm: this.taxForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.taxModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showinvoice(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/showinvoice", item).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.invoiceList = res.data.list;
          this.invoiceForm = {
            province: item.province,
            postofficeid: item.postofficeid,
            month: item.month,
            receive_day: item.receive_day,
            corp: item.corp,
            dispatch_response_corp: item.dispatch_response_corp,
            file: null,
          };
          this.invoiceModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    removeinvoice(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/receiveable/removeinvoice", { _id: item._id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.showinvoice(this.invoiceForm);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    checkfile(file) {
      if (!file) {
        this.$alert("文件不能为空");
        return { code: 1 };
      }
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        this.$alert("文件不能超过10MB");
        return { code: 1 };
      }
      return { code: 0 };
    },
    async doupload() {
      let checkresult = this.checkfile(this.invoiceForm.file);
      if (checkresult.code !== 0) return;
      this.$toast.loading({ message: "" });
      let file = this.invoiceForm.file;
      const slicefiles = this.$utils.sliceFile(file.size);
      const faid = this.$utils.uuid();
      for (let j = 0; j < slicefiles.length; j++) {
        let slicefile = slicefiles[j];
        const formData = new FormData();
        if (slicefile.chunks > 1) {
          let _file = file.slice(slicefile.from, slicefile.to);
          formData.set("file", _file);
        } else {
          formData.set("file", file);
        }
        formData.set("faid", faid);
        formData.set("chunk", slicefile.chunk);
        formData.set("chunks", slicefile.chunks);
        formData.set("originalname", file.name);
        formData.set("province", this.invoiceForm.province);
        formData.set("postofficeid", this.invoiceForm.postofficeid);
        formData.set("month", this.invoiceForm.month);
        formData.set("receive_day", this.invoiceForm.receive_day);
        formData.set("corp", this.invoiceForm.corp);
        formData.set("dispatch_response_corp", this.invoiceForm.dispatch_response_corp);
        try {
          let res = await this.$api.doUpload("/receiveable/plupload", formData);
          if (res.code === 0) {
            if (slicefile.chunk + 1 === slicefile.chunks) {
              this.$toast.clear();
              this.invoiceForm.file = null;
            }
          } else {
            this.$toast.clear();
            this.$alert(res.msg);
            break;
          }
        } catch (e) {
          console.error(e);
          this.$toast.clear();
          this.$alert(e.message);
          break;
        }
      }
      this.showinvoice(this.invoiceForm);
    },
    downloadfile(item) {
      this.$download(item.remoteurl, item.originalname);
    },
  },
};
</script>
<style lang="less" scoped>
p {
  margin: 0;
}

/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
