<template>
  <v-container fluid>
    <div class="mb-4">
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <v-menu ref="start_day_menu" v-model="start_day_menu" :close-on-content-click="false"
            :return-value.sync="queryForm.start_day" transition="scale-transition" min-width="auto" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="queryForm.start_day" label="开始日期" append-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" dense outlined>
                <template v-slot:label>开始日期</template>
              </v-text-field>
            </template>
            <v-date-picker v-model="queryForm.start_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
              @input="() => { $refs.start_day_menu.save(queryForm.start_day); start_day_menu = false; }"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-menu ref="end_day_menu" v-model="end_day_menu" :close-on-content-click="false"
            :return-value.sync="queryForm.end_day" transition="scale-transition" min-width="auto" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="queryForm.end_day" label="结束日期" append-icon="mdi-calendar" readonly v-bind="attrs"
                v-on="on" dense outlined>
                <template v-slot:label>结束日期</template>
              </v-text-field>
            </template>
            <v-date-picker v-model="queryForm.end_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
              @input="() => { $refs.end_day_menu.save(queryForm.end_day); end_day_menu = false; }"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <a-space>
            <v-btn @click="getdata('')" color="cyan" dark tile>
              <v-icon small>mdi-magnify</v-icon>查询
            </v-btn>
            <v-btn @click="getdata('download')" color="indigo" dark tile>
              <v-icon small>mdi-download</v-icon>下载结算明细数据
            </v-btn>
          </a-space>
        </v-col>
      </v-row>
    </div>
    <a-tabs v-model="postofficeid" @change="changetab">
      <a-tab-pane v-for="item in postoffices" :key="item.value" :tab="item.label">
      </a-tab-pane>
    </a-tabs>
    <v-card>
      <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false" bordered
        :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }">
        <template slot="index" slot-scope="text, record,index">
          {{ index + 1 }}
        </template>
        <template slot="real_oilfreight1" slot-scope="text, record">
          {{ record.real_oilfreight1 || record.real_freight1 }}
        </template>
      </a-table>
    </v-card>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";

export default {
  name: "SettleItems",
  data() {
    return {
      queryForm: {
        start_day: this.$moment().format("YYYY-MM-DD"),
        end_day: this.$moment().format("YYYY-MM-DD"),
      },
      postofficeid: '',
      postoffices: [],
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 100, align: "center" },
        { title: "封车日期", dataIndex: "block_day", scopedSlots: { customRender: "block_day" }, width: 120, align: "center" },
        { title: "邮路名称", dataIndex: "postroute", scopedSlots: { customRender: "postroute" }, width: 180, align: "center" },
        { title: "合同里程", dataIndex: "km", scopedSlots: { customRender: "km" }, width: 100, align: "center" },
        { title: "车牌号", dataIndex: "vehicle_number", scopedSlots: { customRender: "vehicle_number" }, width: 120, align: "center" },
        { title: "挂厢号", dataIndex: "vehicle_gnumber", scopedSlots: { customRender: "vehicle_gnumber" }, width: 120, align: "center" },
        { title: "司机姓名", dataIndex: "driver_name", scopedSlots: { customRender: "driver_name" }, width: 100, align: "center" },
        { title: "司机电话", dataIndex: "driver_mobile", scopedSlots: { customRender: "driver_mobile" }, width: 120, align: "center" },
        { title: "计划发车时间", dataIndex: "plan_departure_time", scopedSlots: { customRender: "plan_departure_time" }, width: 150, align: "center" },
        { title: "流水号", dataIndex: "serial_no", scopedSlots: { customRender: "serial_no" }, width: 120, align: "center" },
        { title: "派车单号", dataIndex: "dispatch_no", scopedSlots: { customRender: "dispatch_no" }, width: 150, align: "center" },
        { title: "车型(吨)", dataIndex: "vehicle_type", scopedSlots: { customRender: "vehicle_type" }, width: 100, align: "center" },
        { title: "运费", dataIndex: "real_freight1", scopedSlots: { customRender: "real_freight1" }, width: 100, align: "center" },
        { title: "要求派车", dataIndex: "request_weight", scopedSlots: { customRender: "request_weight" }, width: 100, align: "center" },
        { title: "实际派车", dataIndex: "real_weight", scopedSlots: { customRender: "real_weight" }, width: 100, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center" },
      ],
      list: [],
      height: 600,
      start_day_menu: false,
      end_day_menu: false,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
  },
  mounted() {
    this.height = window.innerHeight - 300;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 300;
    };
    this.list = []
    this.queryForm = {
      start_day: this.$moment().format("YYYY-MM-DD"),
      end_day: this.$moment().format("YYYY-MM-DD"),
    };
    this.getdata();
  },
  methods: {
    changetab(v) {
      console.log('changetab', v);
      this.getdata();
    },
    getdata(action) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/settle/items", { ...this.queryForm, postofficeid: this.postofficeid, action }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          if (action === 'download') return this.$download(res.data.url, res.data.name);
          this.postoffices = res.data.postoffices;
          this.postofficeid = res.data.postofficeid;
          this.list = res.data.list
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-table-tbody>tr>td {
  padding: 4px 4px;
}
</style>
