<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-textarea v-model="queryForm.nos" outlined rows="4" label="派车单号/流水号"></v-textarea>
      </v-col>
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-autocomplete v-model="queryForm.day_type"
              :items="[{ text: '对账日期', value: 'combine_t' }, { text: '流水号日期', value: 'serial_t' }, { text: '封车日期', value: 'block_t' }]"
              label="查询方式" :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-menu ref="start_day_menu" v-model="start_day_menu" :close-on-content-click="false"
              :return-value.sync="queryForm.start_day" transition="scale-transition" min-width="auto" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="queryForm.start_day" label="开始日期" append-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" dense outlined>
                  <template v-slot:label>开始日期</template>
                </v-text-field>
              </template>
              <v-date-picker v-model="queryForm.start_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
                @input="() => { $refs.start_day_menu.save(queryForm.start_day); start_day_menu = false; }"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-menu ref="end_day_menu" v-model="end_day_menu" :close-on-content-click="false"
              :return-value.sync="queryForm.end_day" transition="scale-transition" min-width="auto" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="queryForm.end_day" label="结束日期" append-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" dense outlined>
                  <template v-slot:label>结束日期</template>
                </v-text-field>
              </template>
              <v-date-picker v-model="queryForm.end_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
                @input="() => { $refs.end_day_menu.save(queryForm.end_day); end_day_menu = false; }"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="4">
            <v-autocomplete v-model="queryForm.province" :items="provinces" label="省份"
              :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="4">
            <v-text-field v-model="queryForm.vehicle_number" label="车牌号" outlined dense clearable>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="4">
            <v-autocomplete v-model="queryForm.carrierid" :items="carriers" label="承运商"
              :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="4">
            <v-autocomplete v-model="queryForm.postlineids" :items="postlines" label="线路"
              :menu-props="{ bottom: true, offsetY: true }" dense outlined clearable no-data-text="无" multiple>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="queryForm.postlineids.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      全选
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.label }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ queryForm.postlineids.length - 1 }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="4">
            <v-autocomplete v-model="queryForm.postofficeid" :items="postoffices" label="邮局"
              :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4">
            <v-text-field v-model="queryForm.dispatch_response_corp" label="派车响应单位" dense outlined clearable>
            </v-text-field>
          </v-col>
          <!-- <v-col cols="12" lg="2" md="2" sm="4">
            <v-checkbox v-model="queryForm.use_date" label="使用日期" hide-details dense>
            </v-checkbox>
          </v-col> -->
          <v-col cols="12" lg="3" md="2" sm="4">
            <a-space>
              <v-btn @click="getdata" color="cyan" small dark tile>
                <v-icon small>mdi-magnify</v-icon>查询
              </v-btn>
              <v-btn @click="download" color="indigo" small dark tile>
                <v-icon small>mdi-download</v-icon>下载账单
              </v-btn>
              <UploadTask btnText="上传账单" modelTitle="上传账单" btnSize="small"
                :alertText="`此功能仅用于批量修正<span class='success--text'>考核金额</span>、<span class='success--text'>浮动比例</span>和<span class='success--text'>其他费用</span>，上传Excel文件需包含 <span class='error--text'>邮路名称</span>、<span class='error--text'>派车单号</span> 字段`"
                uploadType="downbill" />
            </a-space>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <p v-if="total_freight" class="error--text text-h6">
      合计：
      <span> 合同价 ({{ total_freight.price }}) </span>,
      <span> 结算运费 ({{ total_freight.freight2 }}) </span>,
      <span> 考核 ({{ total_freight.check_amount2 }}) </span>,
      <span> 保险 ({{ total_freight.insurance }}) </span>,
      <span> 其他费用 ({{ total_freight.other_fee2 }}) </span>,
      <span> 实结运费 ({{ total_freight.real_freight2 }}) </span>
    </p>
    <v-card tile>
      <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false"
        :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }">
        <template slot="title">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center pl-2">
              <span>共 {{ length }} 页 / {{ count }} 条数据</span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                    {{ limit }}/页
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
          </div>
        </template>
        <template slot="customA">
          <p>承运商</p>
          <p>线路</p>
          <p>所属公司</p>
        </template>
        <template slot="customB">
          <p>邮局</p>
          <p>邮路名称</p>
          <p>派车响应单位</p>
        </template>
        <template slot="customC">
          <p>流水号日期</p>
          <p>封车日期</p>
        </template>
        <template slot="customD">
          <p>流水号</p>
          <p>派车单号</p>
        </template>
        <template slot="customE">
          <p>车牌号</p>
          <p>挂厢号</p>
        </template>
        <template slot="customF">
          <p>司机姓名</p>
          <p>司机电话</p>
        </template>
        <template slot="customG">
          <p>往返属性</p>
          <p>货量</p>
        </template>
        <template slot="customH">
          <p>车型</p>
          <p>容积</p>
        </template>
        <template slot="customI">
          <p>要求吨位</p>
          <p>实际吨位</p>
          <p>结算吨位</p>
        </template>
        <template slot="customJ">
          <p>合同里程</p>
          <p>油价联动</p>
          <p>车型比例系数</p>
        </template>
        <template slot="customK">
          <p>合同单价</p>
        </template>
        <template slot="A" slot-scope="text, record">
          <p>{{ record.carrier }}</p>
          <p>{{ record.postline }}</p>
          <p>{{ record.corp }}</p>
        </template>
        <template slot="B" slot-scope="text, record">
          <p class="primary--text" style="border:1px solid #0af">
            {{ record.postoffice }}
          </p>
          <p>{{ record.postroute }}</p>
          <p class="primary--text" style="border:1px solid #0af">
            {{ record.dispatch_response_corp }}
          </p>
        </template>
        <template slot="C" slot-scope="text, record">
          <p>{{ record.serial_day }}</p>
          <p>{{ record.block_day }}</p>
        </template>
        <template slot="D" slot-scope="text, record">
          <p>{{ record.serial_no }}</p>
          <p>{{ record.dispatch_no }}</p>
        </template>
        <template slot="E" slot-scope="text, record">
          <p>{{ record.vehicle_number }}</p>
          <p>{{ record.vehicle_gnumber }}</p>
        </template>
        <template slot="F" slot-scope="text, record">
          <p>{{ record.driver_name }}</p>
          <p>{{ record.driver_mobile }}</p>
        </template>
        <template slot="G" slot-scope="text, record">
          <p>{{ record.transport_kind2 || record.transport_kind }}</p>
          <p>{{ record.transport_amount }}</p>
        </template>
        <template slot="H" slot-scope="text, record">
          <p>{{ record.vehicle_type }}</p>
          <p>{{ record.vehicle_volume }}</p>
        </template>
        <template slot="I" slot-scope="text, record">
          <p>{{ record.request_weight }}</p>
          <p>{{ record.real_weight }}</p>
          <p v-if="record.fee_weight" class="success--text">{{ record.fee_weight }}</p>
          <p v-else class="error--text underline">{{ record.request_weight }}</p>
        </template>
        <template slot="J" slot-scope="text, record">
          <p>{{ record.km }}</p>
          <p>{{ record.oilpricelink }}</p>
          <p>{{ record.vehicle_rate }}</p>
        </template>
        <template slot="K" slot-scope="text, record">
          <p class="success--text">{{ record.price }}</p>
        </template>
        <template slot="carrier_float_rate" slot-scope="text, record">
          <p :class="{ 'error--text': record.carrier_float_rate > 1 }">
            {{ record.carrier_float_rate }}
          </p>
        </template>
        <template slot="check_amount2" slot-scope="text, record">
          <p :class="{ 'error--text': record.check_amount2 < 0, 'success--text': record.check_amount2 > 0 }">
            {{ record.check_amount2 }}
          </p>
        </template>
        <template slot="other_fee2" slot-scope="text, record">
          <p :class="{ 'error--text': record.other_fee2 < 0, 'success--text': record.other_fee2 > 0 }">
            {{ record.other_fee2 }}
          </p>
        </template>
        <template slot="real_freight2" slot-scope="text, record">
          <p :class="{ 'error--text': record.real_freight2 < 0, 'success--text': record.real_freight2 > 0 }">
            {{ record.real_freight2 }}
          </p>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
            <!-- <v-btn v-if="!record.paybilllocked" text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
            <span v-else class="error--text text-h6">应付已锁定</span> -->
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="model" persistent max-width="750px">
      <v-card>
        <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
          选择表头
          <v-icon @click="model = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.carrier" label="承运商" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.postline" label="线路" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.corp" label="所属公司" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.postoffice" label="邮局" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.postroute" label="邮路名称" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.dispatch_response_corp" label="派车响应单位" color="indigo"
                  hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.serial_day" label="流水号日期" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.block_day" label="封车日期" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.serial_no" label="流水号" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.dispatch_no" label="派车单号" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.vehicle_number" label="车牌号" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.vehicle_gnumber" label="挂厢号" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.driver_name" label="司机姓名" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.driver_mobile" label="司机电话" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.transport_kind" label="往返属性" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.transport_amount" label="货量" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.vehicle_type" label="车型" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.vehicle_volume" label="容积" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.request_weight" label="要求吨位" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.real_weight" label="实际吨位" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.fee_weight" label="结算吨位" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.km" label="合同里程" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.oilpricelink" label="油价联动" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.vehicle_rate" label="车型比例系数" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.price" label="合同单价" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.carrier_float_rate" label="浮动比例" color="indigo"
                  hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.freight2" label="结算运费" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.check_amount2" label="考核" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.check_reason2" label="考核原因" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.insurance" label="保险" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.other_fee2" label="其他费用" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.real_freight2" label="实结金额" color="indigo" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-checkbox v-model="downloadForm.remark2" label="备注" color="indigo" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" :disabled="downloadenabled" @click="downloadbill">下载对账单</v-btn>
          <v-btn tile @click="model = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="billModel" persistent max-width="750px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            修改下游账单
            <v-icon @click="cancelupdate">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="error--text text-body-1">
                  <a-row>
                    <a-col :span="8">
                      邮路：{{ billForm.postroute }}
                    </a-col>
                    <a-col :span="8">
                      派车单号：{{ billForm.dispatch_no }}
                    </a-col>
                    <a-col :span="8">
                      流水号：{{ billForm.serial_no }}
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      车牌号：{{ billForm.vehicle_number }}
                    </a-col>
                    <a-col :span="8">
                      挂厢号：{{ billForm.vehicle_gnumber }}
                    </a-col>
                    <a-col :span="8">
                      司机：{{ billForm.driver_name }}/{{ billForm.driver_mobile }}
                    </a-col>
                  </a-row>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="billForm.check_amount2" label="考核" type="number" outlined dense></v-text-field>
                  <v-textarea v-model="billForm.check_reason2" label="考核原因" rows="2" outlined></v-textarea>
                  <v-text-field v-model="billForm.insurance" label="保险" type="number" outlined dense></v-text-field>
                  <v-text-field v-model="billForm.other_fee2" label="其他费用" type="number" outlined dense></v-text-field>
                  <v-text-field v-model="billForm.carrier_float_rate" label="浮动比例" type="number" outlined
                    dense></v-text-field>
                  <v-textarea v-model="billForm.remark2" label="备注" rows="2" outlined></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" :disabled="!valid" @click="updatebill">保存</v-btn>
            <v-btn tile @click="cancelupdate">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import UploadTask from "@/components/uploadtask.vue";
export default {
  name: "FinanceDownBill",
  data() {
    return {
      pages: [
        { text: "20/页", value: 20 },
        { text: "50/页", value: 50 },
        { text: "100/页", value: 100 },
        { text: "200/页", value: 200 },
        { text: '500/页', value: 500 },
        { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      queryForm: {
        nos: "", day_type: "combine_t",
        start_day: this.$moment().format("YYYY-MM-DD"),
        end_day: this.$moment().format("YYYY-MM-DD"),
        province: "", vehicle_number: "", carrierid: "", postlineid: "", postlineids: [], postofficeid: "", dispatch_response_corp: "",
        use_date: false,
      },
      start_day_menu: false,
      end_day_menu: false,
      provinces: [],
      carriers: [],
      postlines: [],
      postoffices: [],
      headers: [
        { dataIndex: "A", slots: { title: "customA" }, scopedSlots: { customRender: "A" }, width: 120, align: "center", },
        { dataIndex: "B", slots: { title: "customB" }, scopedSlots: { customRender: "B" }, width: 180, align: "center", },
        { dataIndex: "C", slots: { title: "customC" }, scopedSlots: { customRender: "C" }, width: 120, align: "center", },
        { dataIndex: "D", slots: { title: "customD" }, scopedSlots: { customRender: "D" }, width: 150, align: "center", },
        { dataIndex: "E", slots: { title: "customE" }, scopedSlots: { customRender: "E" }, width: 150, align: "center", },
        { dataIndex: "F", slots: { title: "customF" }, scopedSlots: { customRender: "F" }, width: 150, align: "center", },
        { dataIndex: "G", slots: { title: "customG" }, scopedSlots: { customRender: "G" }, width: 120, align: "center", },
        { dataIndex: "H", slots: { title: "customH" }, scopedSlots: { customRender: "H" }, width: 120, align: "center", },
        { dataIndex: "I", slots: { title: "customI" }, scopedSlots: { customRender: "I" }, width: 120, align: "center", },
        { dataIndex: "J", slots: { title: "customJ" }, scopedSlots: { customRender: "J" }, width: 120, align: "center", },
        { dataIndex: "K", slots: { title: "customK" }, scopedSlots: { customRender: "K" }, width: 120, align: "center", },
        { title: "浮动比例", dataIndex: "carrier_float_rate", scopedSlots: { customRender: "carrier_float_rate" }, width: 100, align: "center" },
        { title: "结算运费", dataIndex: "freight2", scopedSlots: { customRender: "freight2" }, width: 120, align: "center", },
        { title: "考核", dataIndex: "check_amount2", scopedSlots: { customRender: "check_amount2" }, width: 100, align: "center", },
        { title: "考核原因", dataIndex: "check_reason2", scopedSlots: { customRender: "check_reason2" }, width: 120, align: "center", },
        { title: "保险", dataIndex: "insurance", scopedSlots: { customRender: "insurance" }, width: 100, align: "center", },
        { title: "其他费用", dataIndex: "other_fee2", scopedSlots: { customRender: "other_fee2" }, width: 100, align: "center", },
        { title: "实结金额", dataIndex: "real_freight2", scopedSlots: { customRender: "real_freight2" }, width: 120, align: "center", },
        { title: "备注", dataIndex: "remark2", scopedSlots: { customRender: "remark2" }, width: 200, align: "center", },
        { title: "核销状态", dataIndex: "verify_state2", scopedSlots: { customRender: "verify_state2" }, width: 100, align: "center", },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: "right", },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      model: false,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      downloadForm: {
        carrier: true, postline: true, corp: true,
        postoffice: true, postroute: true, dispatch_response_corp: true,
        serial_day: true, block_day: true, serial_no: true,
        dispatch_no: true, vehicle_number: true, vehicle_gnumber: true,
        driver_name: true, driver_mobile: true, transport_kind: true,
        transport_amount: true, vehicle_type: true, vehicle_volume: true,
        request_weight: true, real_weight: true, fee_weight: true,
        km: true, oilpricelink: true, vehicle_rate: true,
        price: true, carrier_float_rate: true, freight2: true,
        check_amount2: true, check_reason2: true, insurance: true,
        other_fee2: true, real_freight2: true, remark2: true,
      },
      billForm: {},
      billModel: false,
      valid: true,
      total_freight: null,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
    downloadenabled() {
      let values = _.uniq(_.values(this.downloadForm));
      return values.length > 1 || values[0] === false;
    },
    selectAllPostline() {
      return this.queryForm.postlineids.length === this.postlines.length
    },
    selectSomePostline() {
      return this.queryForm.postlineids.length > 0 && !this.selectAllPostline;
    },
    icon() {
      if (this.selectAllPostline) return 'mdi-close-box'
      if (this.selectSomePostline) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  components: { UploadTask },
  watch: {
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 380;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 380;
    };
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.queryForm = {
      nos: "", day_type: "combine_t",
      start_day: this.$moment().format("YYYY-MM-DD"),
      end_day: this.$moment().format("YYYY-MM-DD"),
      province: "", vehicle_number: "", carrierid: "", postlineid: "", postlineids: [], postofficeid: "", dispatch_response_corp: "",
      use_date: false,
    };
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/downbill/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
          this.provinces = res.data.provinces;
          this.postoffices = res.data.postoffices;
          this.postlines = res.data.postlines;
          this.carriers = res.data.carriers;
          this.total_freight = res.data.total_freight;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download() {
      this.model = true;
    },
    downloadbill() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/downbill/list", { ...this.queryForm, downloadForm: this.downloadForm, action: 'download' }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
          this.model = false;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showedit(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/downbill/info", { _id: item._id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.billForm = res.data.billForm;
          this.billModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    updatebill() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/downbill/update", { billForm: this.billForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.billModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelupdate() {
      this.$refs.form.resetValidation();
      this.billModel = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllPostline) {
          this.queryForm.postlineids = []
        } else {
          this.queryForm.postlineids = _.pluck(this.postlines, "value");
        }
      })
    },
    getcarrier(item) {
      return _.findWhere(this.carriers, { value: item.carrierid })?.label;
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}

p {
  margin: 0;
}
</style>
